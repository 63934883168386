import { useDrag } from "react-dnd";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import { ImageListItemWithStyle } from "../../../../../redux/slices/editor/editorModels";
import { Media } from "../../../../../redux/slices/media/mediaModels";
import { ImageListItemBar } from "@mui/material";
import { KTSVG } from "../../../../../../_metronic/helpers";
import Swal from "sweetalert2";
import { formatBytes, secondsToHHmmss, Source } from "../../../../../redux/slices/content/contentModels";
import { setContentSource, setMedia, setSectionId } from "../../../../../redux/slices/content/contentSlice";
import { useDeleteMediaMutation } from "../../../../../redux/slices/media/apiMediaSlice";
import moment from "moment";

type Props = {
    className: string,
    item: Media,
    inUse?: number,
    deletable?: boolean,
    isSelected?: boolean,
    isDraggable?: boolean,
}

const DraggableAudio: React.FC<Props> = ({ className, item, inUse = 0, isSelected = false, deletable = true, isDraggable = true }) => {
    const dispatch = useAppDispatch()
    const [deleteDraggableImage] = useDeleteMediaMutation()
    // --------------------------------------------
    const currPersistContent = useAppSelector(state => state.persistor._content.currContent)
    const currentFolder = useAppSelector(state => state.editor.currentFolder)
    const multiSelMode = useAppSelector(state => state.editor.multiSelectMode)
    
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: "imageVideo",
            item: {
                id: item.source_id,
            },
            collect: (monitor) => {
                return {
                    isDragging: !!monitor.isDragging(),
                }
            },
        }), [item]  // Require to refresh obj 'item' when it changes, DEPENDENCY ARRAY HERE, need for refreshing
    )

    const limitFileNamelen = 11
    const fileName = item.name.split('.').slice(0, -1).join('.')
    const ext = item.name.split('.').pop() 

    const deleteMediaHandler = async (med: Media, inUse = 0) => {
        if (inUse > 0 || currentFolder.source_group_id === "bin" || (!isDraggable)) {
            Swal.fire({
                title: 'Are you sure you want to <br/>' + (currentFolder.source_group_id === "bin" || (!isDraggable) ? "permanently" : "") + ' delete this file?<h2 style="color:#331168; margin: 15px 0;">' + med.name + '</h2>',
                // text: "This file is in use!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#9C258F',
                cancelButtonColor: '#E4E6EF',
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    cancelButton: 'order-1 left-gap me-5',
                    confirmButton: 'order-2',
                    // denyButton: 'order-3',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    try {
                        if (med.source_id) {
                            // 2- Delete images from currContent
                            currPersistContent.elements.map((section, indSect) => {
                                var sourcesAfterDelete: Source[] = []
                                if (["audio"].includes(section.element_type.toLowerCase())) {
                                    sourcesAfterDelete = section.sources?.filter((src) => {
                                        return (src.source && src.source.source_id !== med.source_id)
                                    })
                                    if (section.sources.length !== sourcesAfterDelete.length) {
                                        dispatch(setSectionId(section.element_id))  //which section it belongs to
                                        dispatch(setContentSource(sourcesAfterDelete))
                                    }
                                }
                            });
                            // 3 - Delete the  source
                            deleteDraggableImage(med.source_id)

                            Swal.fire({
                                icon: 'success',
                                title: `Deleted! <br/><br/>'${med.name}'<br/><br/> has been succesfully deleted.<br/><br/>&nbsp;`,
                                showConfirmButton: false,
                                timer: 2000
                            })
                        }
                    } catch (error) {
                        Swal.fire(
                            'File: ' + med.name,
                            'There is something wrong during the deletion!',
                            'error'
                        )
                    }
                }
            })
        } else {
            try {
                if (med.source_id) {
                    // 2- Delete images from currContent
                    currPersistContent.elements.map((section, indSect) => {
                        var sourcesAfterDelete: Source[] = []
                        if (["audio"].includes(section.element_type.toLowerCase())) {
                            sourcesAfterDelete = section.sources?.filter((src) => {
                                return (src.source && src.source.source_id !== med.source_id)
                            })
                            if (section.sources.length !== sourcesAfterDelete.length) {
                                // console.log("delete this")

                                dispatch(setSectionId(section.element_id))  //which section it belongs to
                                dispatch(setContentSource(sourcesAfterDelete))
                            }
                        }
                    })
                    // 3 - Delete the image source
                    // ----------------------------
                    deleteDraggableImage(med.source_id)
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-right',
                        iconColor: 'green',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                        // timerProgressBar: true
                    })
                    await Toast.fire({
                        icon: 'success',
                        title: `File: <span class="text-primary fw-bold">'${med.name}'</span><br/> has been successfully deleted.`,
                    })
                }
            } catch (error) {
                Swal.fire(
                    'File: ' + med.name,
                    'There is something wrong during the deletion!',
                    'error'
                )
            }
        }
    }
    return (
        <ImageListItemWithStyle
            ref={currentFolder.source_group_id !== 'bin' && isDraggable ? drag : null}
            className={`w-100 border ${isDraggable || multiSelMode ? "" : "pe-none"} `}
            style={{overflowX: "hidden"}}
        >
            <div className='rounded-0 w-100 h-80px bg-white bordered-1 border-light-primary-darker'>
              <div className="h-100 d-flex justify-content-center top-50 align-items-center text-center">
              <audio  playsInline={true} controls autoPlay={false} src={`${item.url}`} />
              </div>
            </div>

            <ImageListItemBar
                position='top' className='rounded-top bg-transparent'
                actionIcon={<>
                    {inUse > 0 &&
                        <span className='opacity-100 m-0 pt-1 pb-0 px-1 position-absolute top-0 start-0 bagde tooltip-bottom'
                            style={{ borderBottomRightRadius: "5px" }}
                            data-tooltip-content='This file is in use!'
                        >
                            <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-3 text-light shadow-sm ms-1' />
                        </span>
                    }
                    {isSelected === true &&
                        <span className='opacity-100 m-0 mt-1 ms-1 p-1 pb-0 position-absolute top-0 start-0 bagde bg-info rounded-circle'
                            style={{ borderBottomRightRadius: "5px" }}
                        >
                            <i className='bi bi-check text-light fw-bold fs-3 shadow-sm' />
                        </span>
                    }
                    {deletable === true &&
                        <a href="#"
                            className={`pe-auto bg-secondary bg-hover-primary opacity-75 m-0 py-0 px-2 position-absolute top-0 end-0 tooltip-bottom`}
                            data-tooltip-content={`${currentFolder.source_group_id !== "bin" ? 'Move to Trash' : ''}`}
                            style={{ borderBottomLeftRadius: "5px" }}
                            onClick={() => { deleteMediaHandler(item, inUse); }}
                        >
                            <i className='bi bi-trash text-dark text-hover-white opacity-75'></i>
                        </a>
                    }
                </>}
            />
           
            <div className='d-flex justify-content-between m-1 mb-2'>
                <span className='d-flex fs-9 fw-semibold text-muted tooltip-bottom'
                    data-tooltip-content={item.name}
                >
                    <i className='fa fa-music text-info opacity-100 me-2'></i>
                    {`${fileName.length <= limitFileNamelen ? fileName : fileName.substring(0, limitFileNamelen) + "..."}.${ext}`}
                </span>
                <span className='fs-9 fw-semibold text-muted'>{formatBytes(item.size)}</span>
            </div>
            {item.deleted_at &&
                <div className='d-flex justify-content-end fs-9 text-muted pe-1'>
                    {moment.utc(moment(item.deleted_at, true).toString()).local().fromNow()}
                    {/* {RECYCLE_BIN_DURATION - moment.utc(moment(item.deleted_at, true).toString()).local().diff(moment(), "days")} days left    */}
                </div>
            }
        </ImageListItemWithStyle>
    )
}

export { DraggableAudio }