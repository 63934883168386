import {FC, useState, useEffect} from 'react'
import { TabAI, TabGallery, TabMedia, TabTemplates } from '../EditorComponents';
import { TabText } from './tabContents/tabText/TabText';

type Props = {
  link: string
}


const Tabs: ReadonlyArray<{link: string; icon: string; tooltip: string, hidden?:boolean}> = [
  
    // {
    //   link: 'resize',
    //   icon: '/media/icons/duotune/art/art006.svg',
    //   tooltip: 'Resize',
    // },  
    {
      link: 'template',
      icon: '/media/icons/duotune/layouts/lay001.svg',
      tooltip: 'Template',
      // hidden: false
    },    
    // {
    //   link: 'media',
    //   icon: '/media/icons/duotune/general/gen006.svg',
    //   tooltip: 'Media',
    //   hidden: false
    // },    
    {
      link: 'gallery',
      // icon: '/media/icons/duotune/general/gen006.svg',
      icon: '/media/icons/duotune/files/fil012.svg',
      tooltip: 'Gallery',
    },
    {
      link: 'ai',
      icon: '/media/icons/duotune/abstract/abs021.svg',
      tooltip: 'AI',
      hidden: false
    },
    // {
    //   link: 'text',
    //   icon: '/media/icons/duotune/art/art001.svg',
    //   tooltip: 'Text',
    // },    
    // {
    //   link: 'medias',
    //   icon: '/media/icons/duotune/general/gen006.svg',
    //   tooltip: 'Your Medias',
    // },
    // {
    //   link: 'content',
    //   icon: '/media/icons/duotune/abstract/abs027.svg',
    //   tooltip: 'Contents',
    // },
    
    {
      link: 'videos',
      icon: '/media/icons/duotune/social/soc007.svg',
      tooltip: 'Videos',
      hidden: true
    },
    
    // for instant display the uploaded excel, word, pdf files 
    // It means it generate the html code immediately after uploading the file.
    // we can use google file viewer to help like hino project for pdf, not sure about excel or doc??
    // using iframe 
    {
      link: 'documents',
      icon: '/media/icons/duotune/files/fil003.svg',
      tooltip: 'Documents', 
      hidden: true
    },
    {
      link: 'apps',
      icon: '/media/icons/duotune/general/gen025.svg',
      tooltip: 'Apps', 
      hidden: true
    },
    // from Optsign
    {
      link: 'QR Code',
      icon: '/media/icons/duotune/general/gen008.svg',
      tooltip: 'QR Code', 
      hidden: true
    },
    
    // {
    //   link: 'notifications',
    //   icon: '/media/icons/duotune/abstract/abs027.svg',
    //   tooltip: 'Elements',
    // },
    // {
    //   link: 'widget',
    //   icon: '/media/icons/duotune/files/fil005.svg',
    //   tooltip: 'Widget',
    // }  
]


const SelectedTab: FC<Props> = ({link}) => {
  switch (link) {
    case 'template':
      return <TabTemplates />
    case 'gallery':
      return <TabGallery />
    case 'ai':
      return <TabAI />
    // case 'media':
    //   return <TabMedia />
    // case 'text':
    //   return <TabText />
    // case 'medias':
    //   return <TabMedias />
    // case 'apps':
    //   return <TabApps />
    // case 'subscription':
    //   return <SubscriptionsTab />
    // case 'tasks':
    //   return <TasksTab />
    // case 'notifications':
    //   return <NotificationsTab />
    // case 'authors':
    //   return <AuthorsTab />
    default:
      return <TabTemplates />
      // return <TabGallery />
  }
}

export {
  Tabs,
  SelectedTab,

}