import { FC, Fragment, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useAppSelector, useAppDispatch } from "../../../../../redux/hook";
import { 
  enableEditorMode, 
  setCurrentEditorWidth, 
  setCurrentEditorHeight, 
  setEditorStatusParams
} from '../../../../../redux/slices/editor/editorSlice'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddContentMutation, useGetAllContentsQuery, useUpdateContentMutation } from '../../../../../redux/slices/content/apiContentSlice';
import { AssociativeArray, Content, ContentInput, isEmptyElements, isSimilarContent } from '../../../../../redux/slices/content/contentModels'
import { setCheckSum, setContent, setContentName, setPrevContent } from '../../../../../redux/slices/content/contentSlice'
import html2canvas from 'html2canvas'
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

const schema = yup.object().shape({
  contentId: yup.string()  
})

const SaveContentFrm = () => {

  const dispatch = useAppDispatch()
  // ----------------------------------------------------------------------    
  const [addContent, resultAddContent] = useAddContentMutation();  
  const [updateContent] = useUpdateContentMutation();  

  const editorStatusParams = useAppSelector(state => state.editor.editorStatusParams)
  // console.log("editorStatusParams: ", editorStatusParams)
  
  const currPersistContent = useAppSelector(state => state.persistor._content.currContent)
  const prevPersistContent = useAppSelector(state => state.persistor._content.prevContent)

  // console.log("currPersistContent: ", currPersistContent)
  // console.log("prevPersistContent: ", prevPersistContent)

  const { data: allContents, isLoading: isLoadingContents, isFetching, refetch: refetchContents} = useGetAllContentsQuery(5, { refetchOnMountOrArgChange: true })  
  const [existContentNames, setExistContentNames] = useState<string[]>([])

  const [contName, setContName] = useState(currPersistContent.user_template_name)
  // const [activeSaveBtn, setActiveSaveBtn] = useState(false)
  // console.log("activeSaveBtn: ", activeSaveBtn? "true":"false")

  const contentIDInUse : AssociativeArray[] = useAppSelector(state => state.persistor._content.contentIDInUse) // for just pure a list of all sched
  const status =  currPersistContent.user_template_id && Object.keys(contentIDInUse).includes(currPersistContent.user_template_id)? "active":"inactive"


  //--------------------------------------  
  const { 
    register, 
    handleSubmit,
    // control,
    reset,
    watch,
    setError,
    formState:{
        errors,
        isLoading,         
        isValid,   
        isSubmitting,
        isSubmitSuccessful,        
    }
  } = useForm<any>({
      resolver: yupResolver(schema),
      defaultValues:{         
        contentId:  currPersistContent?currPersistContent.user_template_id:"",  
        user_template:{
          user_template_name  : currPersistContent.user_template_name,
          template_id: currPersistContent.template_id,
          duration: currPersistContent.duration,          
        },
        elements:[]        
      }
  })

  // console.log(" watch: ", watch())

  // const saveAs = (blob: any, fileName: string) =>{
  //   var elem = window.document.createElement('a');
  //   elem.href = blob
  //   elem.download = fileName;
  //   // elem.style = 'display:none;';
  //   (document.body || document.documentElement).appendChild(elem);
  //   if (typeof elem.click === 'function') {
  //       elem.click();
  //   } else {
  //       elem.target = '_blank';
  //       elem.dispatchEvent(new MouseEvent('click', {
  //       view: window,
  //       bubbles: true,
  //       cancelable: true
  //       }));
  //   }
  //   URL.revokeObjectURL(elem.href);
  //   elem.remove()
  // }


  // ---------------------------
  const buildPng = async (input: HTMLDivElement) => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const filter = (node: HTMLElement) => {
      const exclusionClasses = ['remove-me', 'secret-div'];
      return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    }

    let dataUrl = '';
    let canvas;
    /*
    let i = 0;
    let maxAttempts;
    if (isSafari) {
      maxAttempts = 5;
    } else {
      maxAttempts = 1;
    }
    let cycle = [];
    let repeat = true;
    
    while (repeat && i < maxAttempts) {
      // dataUrl = await toPng(input, {
      //   skipFonts: true,
      //   // backgroundColor: 'white',
      //   fetchRequestInit: {
      //     mode:'cors',
      //     cache: 'no-cache',
      //   },        
      //   cacheBust: true,
      //   // fetchRequestInit: {
      //   //   cache: 'no-cache',
      //   // },
      //   skipAutoScale: true,
      //   includeQueryParams: true,
        
      //   pixelRatio: isSafari ? 1 : 3,
      //   quality: 1,
      //   filter: filter,
      //   style: { paddingBottom: '100px' },
      // });
       i += 1;      
       cycle[i] = dataUrl.length;

       if (dataUrl.length > cycle[i - 1]) repeat = false;
      */
      canvas = await htmlToImage.toCanvas(input as HTMLImageElement, {
        fetchRequestInit: {
          // mode:'cors',
          cache: "no-cache",
        },
        cacheBust: true,
        skipFonts: true,
        // backgroundColor: "#9C258F",
        skipAutoScale: true,
        includeQueryParams: true,
        pixelRatio: isSafari ? 1 : 3,
        quality: 1,
        filter: filter,
        style: { paddingBottom: '100px' },
      });
      dataUrl = canvas.toDataURL("image/png");
      
    /*  
      i += 1; 
      cycle[i] = dataUrl.length;
      if (dataUrl.length > cycle[i - 1]) repeat = false;
    }
    */
    //console.log('safari:' + isSafari + '_repeat_need_' + i);
    return dataUrl;
  };


  // -------------------------------------
  const formSubmitHandler: SubmitHandler<any> = async (content: ContentInput) => {        
    
    // if (activeSaveBtn){
    if (editorStatusParams.savingStatus <= 0){      
      if (content.user_template.user_template_name.length === 0){
        Swal.fire({
          title: 'Campaign Name',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },        
          icon: 'question',
          showConfirmButton: true,
          confirmButtonColor: '#9C258F',
          confirmButtonText: '<span class="mx-5"><i class="fa fa-save text-white me-2"></i>Save </span>',
          showCancelButton: true,
          cancelButtonText: '<span class="mx-5"><i class="fa fa-times text-white me-2"></i>Cancel </span>',
          showLoaderOnConfirm: true,
          preConfirm: (name) => {
              if (!name) {
                Swal.showValidationMessage('You need to write something!')
              }else{
                if (existContentNames.includes(name.toLowerCase())){
                  Swal.showValidationMessage('This name already exist!')
                }
              } 
            return name        
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
          if (result.isConfirmed) {
            const campaignName = (result.value !== ""? result.value : "NONAME")

            content.user_template.user_template_name = campaignName
            setContName(campaignName)
            dispatch(setContentName(campaignName))

            try{
              if(content.contentId === ""){

                // set status to Saving
                // -----------------------
                dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 1})) 

                //---generate thumbnails
                //----------------------------                             
                const input = document.getElementById("editor-panel")                 
                if (input){                  
                  // htmlToImage.toPng(input, {
                  //     // fetchRequestInit: {
                  //     //   cache: 'no-cache',
                  //     // },
                  //     // skipAutoScale: true,
                  //     // includeQueryParams: true,
              
                  //     // pixelRatio: isSafari ? 1 : 3,
                  //     // quality: 1,
                  //     // filter: filter,
                  //     skipFonts: true,
                  //     // style: { paddingBottom: '100px' },
                  //   }
                  // )
                  // .then(async (imgData) => {
                  // html2canvas(input, { 
                  //   useCORS : true,
                  //   logging : false,
                  //   // scale: 0.5,
                  //   scale: Math.min(window.devicePixelRatio, 2)
                  // }).then(async (canvas) => {                    
                  //   const imgData = canvas.toDataURL("image/png");  


                  await buildPng(input as HTMLDivElement).then(async(imgData) => {

                    const tmpContent =  {
                                          ...content,
                                          user_template:  {
                                                            ...content.user_template,
                                                            thumbs: [imgData]
                                                          }                     
                                        }

                    const response :any = await addContent(tmpContent)  
                    // console.log("response from addContent:", response)

                    const str = '<a  href="#" data-bs-toggle="offcanvas" aria-controls="staticBackdrop" data-bs-target="#kt_drawer_instant_play"'+        
                                'id="kt_drawer_instant_play_button" data-kt-drawer-show="true">' +
                                '<button class="btn tooltip-bottom btn-gradient-primary w-75" data-tooltip-content="Instant Play" data-bs-dismiss="modal" aria-label="Close">'+
                                '<i class="bi bi-lightning-fill text-white fs-4 me-2"></i>Let\' Superbolt Now!'+
                                '</button>'+
                                '</a><br/>&nbsp;'
    
                    if (response.data){
                      Swal.fire({
                        icon: 'success',
                        title:  'Your campaign has been created.<br/><br/>'+ str, 
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true
                      })

                      refetchContents()                      
                      dispatch(setCheckSum(Math.random()))

                      dispatch(setPrevContent(currPersistContent))

                      // set status to saved 
                      // ------------------
                      dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 2})) 

                    }else{
                      Swal.fire({
                        icon: 'error',
                        title:  'Unable to create your campaign.<br/>', 
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true
                      })
                      
                      dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 0})) // set status to unsaved                       
                    }
                    
                  })
                  .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                    dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 0})) // set status to unsaved 
                  })
                } 
                
                               
                                
              }
            }catch(error){
              Swal.fire(
                  'Campaign: '+ content.user_template.user_template_name,
                  'There is something wrong during the creation!',
                  'error'
              )
            } 
          }
        })        
      }else{        
        // if an active content
        if (currPersistContent.user_template_id && currPersistContent.user_template_id !== "" && Object.keys(contentIDInUse).includes(currPersistContent.user_template_id)){
          Swal.fire({            
            
            title: "This will push updated changes to<br/> the current <span class='text-success fw-bold'>active campaign</span> immediately.",          
            text : "Do you want to continue?",
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonColor: '#9C258F',
            confirmButtonText: '<span class="mx-5"><i class="bi bi-check2 text-white me-3"></i>Yes, proceed</span>',
            showCancelButton: true,
            cancelButtonText: '<span class="mx-5"><i class="fa fa-times text-white me-2"></i>Cancel </span>',
          }).then(async (result) => {        
            if (result.isConfirmed)  {

              try{
                if(content.user_template.user_template_name !== ""){
                  
                  // set status to Saving 
                  // ------------------
                  dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 1}))
                  
                  //---generate thumbnails
                  //----------------------------
                  const input = document.getElementById("editor-panel");
                  // console.log("input:", input)

                  if (input){
                    // htmlToImage.toPng(input, {
                    //     // fetchRequestInit: {
                    //     //   cache: 'no-cache',
                    //     // },
                    //     // skipAutoScale: true,
                    //     // includeQueryParams: true,
                
                    //     // pixelRatio: isSafari ? 1 : 3,
                    //     // quality: 1,
                    //     // filter: filter,
                    //     skipFonts: true,
                    //     // style: { paddingBottom: '100px' },
                    //   }
                    // )
                    // .then(async (imgData) => {

                    // html2canvas(input, { 
                    //   useCORS : true,
                    //   logging : false,
                    //   // scale: 0.5,
                    //   scale: Math.min(window.devicePixelRatio, 2)
                    // }).then(async (canvas) => {
                    // const imgData = canvas.toDataURL("image/png");  
                    
                    await buildPng(input as HTMLDivElement).then(async(imgData) => {

                      const tmpContent =  {
                                            ...content,
                                            user_template:  {
                                                              ...content.user_template,
                                                              thumbs: [imgData]
                                                            }                     
                                          }

                      const response: any = await updateContent(tmpContent)  
                      // console.log("response from update active campaign:", response)

                      
                      if (response.data){

                        const Toast = Swal.mixin({
                          toast: true,
                          position: 'bottom-right',
                          iconColor: 'green',
                          customClass: {
                            popup: 'colored-toast'
                          },
                          showConfirmButton: false,
                          timer: 2000,
                        })
                        Toast.fire({
                            icon: 'success',
                            title: `Campaign <span class='text-primary text-capitalize'>${currPersistContent.user_template_name}</span><br/> is successfully updated.`,
                        })

                        refetchContents()
                        dispatch(setCheckSum(Math.random()))

                        dispatch(setPrevContent(currPersistContent))

                        // set status to saved 
                        // ------------------
                        dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 2}))

                      }else{
                        const Toast = Swal.mixin({
                          toast: true,
                          position: 'bottom-right',
                          iconColor: 'red',
                          customClass: {
                            popup: 'colored-toast'
                          },
                          showConfirmButton: false,
                          timer: 2000,
                        })
                        Toast.fire({
                            icon: 'error',
                            title: `Unable to update your campaign <span class='text-primary text-capitalize'>${currPersistContent.user_template_name}</span>.`,
                        })

                        // set status to unsaved 
                        // ------------------
                        dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 0}))
                      }
                    });
                  }                  
                  
                }
              }catch(error){
                Swal.fire(
                    'Campaign: '+ content.user_template.user_template_name,
                    'There is something wrong during the updating!',
                    'error'
                )
              }
            }
          }) 
        }else{
          // Update inactive campaign
          try{
            if(content.user_template.user_template_name !== ""){

              // set status to Saving
              // -----------------------
              dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 1})) 
              
              //---generate thumbnails
              //----------------------------                             
              const input = document.getElementById("editor-panel")                 
              if (input){                                                           

                // html2canvas(input, { 
                //   useCORS : true,
                //   logging : false,
                //   scale: 0.5,
                //   // scale: Math.min(window.devicePixelRatio, 2)
                // }).then(async (canvas) => {
                //   const imgData = canvas.toDataURL("image/png");  
                //   console.log("imgData", imgData)             
  
                await buildPng(input as HTMLDivElement).then(async(imgData) => {
                  
                  const tmpContent =  {
                                        ...content,
                                        user_template:  {
                                                          ...content.user_template,
                                                          thumbs: [imgData]
                                                        }                     
                                      }

                  const response: any = await updateContent(tmpContent)  
                  // console.log("update response from not active campaign update:", response)

                  if (response.data){                   
                    
                    const Toast = Swal.mixin({
                      toast: true,
                      position: 'bottom-right',
                      iconColor: 'green',
                      customClass: {
                        popup: 'colored-toast'
                      },
                      showConfirmButton: false,
                      timer: 2000,
                    })
                    Toast.fire({
                        icon: 'success',
                        title: `Campaign <span class='text-primary text-capitalize'>${currPersistContent.user_template_name}</span><br/> is successfully updated.`,
                    })

                    refetchContents()
                    dispatch(setCheckSum(Math.random()))

                    dispatch(setPrevContent(currPersistContent))

                    // set status to saved 
                    // ------------------
                    dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 2}))

                  }else{
                    const Toast = Swal.mixin({
                      toast: true,
                      position: 'bottom-right',
                      iconColor: 'red',
                      customClass: {
                        popup: 'colored-toast'
                      },
                      showConfirmButton: false,
                      timer: 2000,
                    })
                    Toast.fire({
                        icon: 'error',
                        title: `Unable to update your campaign <span class='text-primary text-capitalize'>${currPersistContent.user_template_name}</span>.`,
                    })

                    // set status to unsaved 
                    // ------------------
                    dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 0}))
                  }
                }).catch(function (error) {
                  console.error('oops, something went wrong!', error);                  
                  dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 0}))
                });
              }              
            }
          }catch(error){
            Swal.fire(
                'Campaign: '+ content.user_template.user_template_name,
                'There is something wrong during the updating!',
                'error'
            )
            dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 0}))
          }
        }

      }
    }else{
      Swal.fire({
        // position: 'bottom-end',
        icon: 'error',
        title: 'Unable to save an empty campaign.<br/>&nbsp;',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }
 

  // -------------------------------------
  useEffect(() => {
    if (resultAddContent.status === "fulfilled"){
      dispatch(setContent(resultAddContent.data));
    }
  }, [resultAddContent])


  // -------------------------------------
  useEffect(() => {
 
    //Assigned the prevContent for first time, after saving
    if (prevPersistContent.user_template_name === "" && prevPersistContent.user_template_id === "" &&
        currPersistContent.user_template_name !== "" && currPersistContent.user_template_id !== ""){
          // console.log("save for the first time...........................")
          setTimeout(() => {
            dispatch(setPrevContent(currPersistContent))             
          }, 2000);
    }

    //----------
    dispatch(enableEditorMode())
    dispatch(setCurrentEditorWidth(currPersistContent.width))
    dispatch(setCurrentEditorHeight(currPersistContent.height))
    setContName(currPersistContent.user_template_name)


    var eleList:any[] = []
    currPersistContent.elements?.map((section, index) => {
      eleList.push({
        element_id  : section.element_id,
        position_id : section.position_id,
        alias       : section.alias,
        element_setting : JSON.stringify(section.element_setting),
        type            : section.element_type,
        source_ids      : JSON.stringify(section.sources),
      })
    })

    const newCont = {
                      contentId:  currPersistContent?currPersistContent.user_template_id:"",  
                      user_template:{
                        user_template_name  : currPersistContent.user_template_name,
                        template_id         : currPersistContent.template_id,
                        duration            : currPersistContent.duration,          
                      },
                      elements: eleList
                    }
    reset(newCont)    
    
  }, [currPersistContent])

  // ----------------------------------------------
  useEffect(()=>{
    

    // to activate the save button
    // const comparisonFlag = !( emptyContFlag || simContFlag)
    // setActiveSaveBtn(comparisonFlag)  //content is changed and not empty

    if (editorStatusParams.savingStatus !== 1){ //avoid updating status while saving
      const emptyContFlag = isEmptyElements(watch("elements"))    
      // ------------------
      if (emptyContFlag){      
        dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: -1})) //content is empty
      }else{
        const simContFlag   = isSimilarContent(currPersistContent, prevPersistContent)
        // ------------------
        if (!( emptyContFlag || simContFlag)){
          dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 0})) //content is not saved yet
        }else{
          dispatch(setEditorStatusParams({...editorStatusParams, savingStatus: 2})) //content is saved
        }
      }
    }
  },[currPersistContent, prevPersistContent])
  
  // ----------------------------------------------
  useEffect(()=>{
    setExistContentNames(allContents?allContents.map((cont: Content)=> cont.user_template_name.toLowerCase()):[])
  },[allContents])




  // -------------------------------------------
  return (<>      
     
    <form id="frm-add-device" className='form d-inline'  
      onSubmit={handleSubmit(formSubmitHandler)}
    >
      <input type="hidden" {...register("contentId")} value={currPersistContent.user_template_id} />
      <input type="hidden" {...register("user_template[user_template_name]")} value={contName} />
      <input type="hidden" {...register("user_template[template_id]")} value={currPersistContent.template_id} />
      <input type="hidden" {...register("user_template[duration]")} value={currPersistContent.duration} />
      {currPersistContent.elements?.map((section, index) => ( 
        <Fragment key={index}>
          <input type='hidden' {...register(`elements[${index}][element_id]`)} value={section.element_id} />
          <input type='hidden' {...register(`elements[${index}][position_id]`)} value={section.position_id} />
          <input type='hidden' {...register(`elements[${index}][alias]`)} value={section.alias} />
          <input type='hidden' {...register(`elements[${index}][element_setting]`)} value={JSON.stringify(section.element_setting)} />

          <input type='hidden' {...register(`elements[${index}][type]`)} value={section.element_type} />
          <input type='hidden' {...register(`elements[${index}][source_ids]`)} value={JSON.stringify(section.sources)} />
        </Fragment> 
      ))}

      {/* <button 
        type='button'
        className='btn btn-link'
        onClick={printDocument}>
        print
      </button> */}
      <button 
        type="submit" 
        className={`btn btn-primary btn-sm tooltip-bottom`} 
        data-tooltip-content={currPersistContent.user_template_id === "" ? "Save new content": "Save content"}        
        disabled={isSubmitting || !(isValid && ([0].includes(editorStatusParams.savingStatus)) )}
      >
        {
          [-1,0,2].includes(editorStatusParams.savingStatus)  
            ? <span><i aria-hidden="true" className="fs-4 mt-n1 fa fa-save"></i> Save</span>
            : [1].includes(editorStatusParams.savingStatus) &&  
                <div className='d-flex'>
                  <div className="spinner-border w-15px h-15px me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <span>Saving</span>
                </div>                    
        }
        
        {/* {currPersistContent.user_template_id === "" ? "Save": "Save"} */}
      </button>       
       
    </form> 
  </>)
}

export {SaveContentFrm}
