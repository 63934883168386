import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { DrawerEditPlaylist, TablePlaylists } from './PlaylistComponents'
import { useAppDispatch } from '../../../redux/hook'
import { useGetAllPlaylistsQuery } from '../../../redux/slices/content/apiContentSlice'
import { PlaylistHeader } from '../PlaylistHeader'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ModalPreviewContent } from '../../content/components/ContentComponents'
import { DrawerAddInstantSchedule } from '../../schedule/components/ScheduleComponents'
import { DrawerAddPlaylist } from './PlaylistComponents'
import { useInView } from "react-intersection-observer";
import { ModalShareCampaign } from '../../content/components/modals/ModalShareCampaign'

const Index: FC = () => {
  const dispatch = useAppDispatch()
  //-------------------------------------------
  const { data: allPlaylists, isLoading : isLoadingAllPlaylist, isFetching: isFetchingAllPlaylist} = useGetAllPlaylistsQuery()  
  // console.log("allPlaylists", allPlaylists)  
  //----------------------------------------
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  //----------------------------
  return (
    <div ref={ref}>
      <PageTitle breadcrumbs={[]}>Your Playlist</PageTitle>

      <div className='d-flex flex-row h-100 bg-gray-200 mb-9 p-0 rounded-bottom'>
        <div className='d-flex flex-column flex-row-fluid'>
          <div className="d-flex flex-column-auto flex-start">            
            <PlaylistHeader className='w-100 h-100 py-2'/>            
          </div>
        </div>
      </div>     

      {!allPlaylists || allPlaylists.length <= 0 && 
        <div className='row gy-5 gx-xl-8 h-md-300 mb-7'>    
          <div className='col-lg-12 col-xl-12 d-flex flex-center'>          
            <img src={toAbsoluteUrl('/media/_content/banner-empty-content-01.png')} className='w-50 mt-10 opacity-25' />
          </div>
          <div className='col-lg-12 col-xl-12 text-center '>
            <h1 className='text-muted'>Playlist is Empty.</h1>
            <div className='fs-3 mt-5'>
              <i className='fa fa-hand-point-right text-gray-900 text-hover-primary fs-4 mt-n1 me-2'></i>
              Click 
              <a href="#" className='btn btn-sm bg-secondary bg-hover-light-primary px-2 py-1 mx-2'>
              <button 
                  className={`btn text-hover-primary bg-hover-light-primary tooltip-bottom`}
                  // data-tooltip-content='Add a new group' 
                  // className="btn btn-primary me-5 tooltip-bottom"
                  data-bs-toggle="offcanvas"
                  aria-controls="staticBackdrop"
                  data-bs-target="#kt_drawer_add_playlist"
                  
                  id="kt_drawer_add_playlist_button"
                  data-kt-drawer-show="true" 
                  
                  data-tooltip-content="Let's create a better content for a greater benefit."
                  // onClick={() => { dispatch(resetContentLandscape()); }}
              >
                  <i className='bi bi-plus-circle text-hover-primary fs-4 mt-n1 me-1'></i>
                  <span className='fs-3'>Create new playlist</span>
              </button>
            </a>
              to design your first playlist!
            </div>
          </div>        
        </div>    
      }

      {allPlaylists && allPlaylists.length > 0 &&   
        <div className='row gy-5 gx-xl-8 h-md-300 mb-7'>        
          <div className='col-lg-12 col-xl-12'>          
            <TablePlaylists
              className='card-xxl-stretch mb-5 mb-xl-8'
              // allContents = {allContents}
              inView={inView}
              />
          </div>        
        </div>
      }
      
      <ModalPreviewContent className="" id="modal-preview-content"/>
      <ModalShareCampaign className='' id='modal-share-campaign' />
      <DrawerAddInstantSchedule />
      <DrawerAddPlaylist inView={inView}/>
      <DrawerEditPlaylist/>
      {/* <DrawerUpdateSchedule />
      <DrawerViewScheduleDetail />
      <DrawerViewGroupDetail /> */}
    </div>
  )
}

export {Index}