/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import { useDrag } from 'react-dnd'
import { IconButton, ImageListItem, ImageListItemBar } from '@mui/material'
import { ContentInput, EleInput, Source, formatBytes } from '../../../../../redux/slices/content/contentModels'
import { Media } from '../../../../../redux/slices/media/mediaModels'
import { useDeleteMediaMutation } from '../../../../../redux/slices/media/apiMediaSlice'
import Swal from 'sweetalert2'
import { useGetAllContentsQuery, useUpdateContentMutation } from '../../../../../redux/slices/content/apiContentSlice'
import { setContent, setContentSource, setSectionId } from '../../../../../redux/slices/content/contentSlice'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hook'
import { DEFAULT_LOAD_ATTEMPT, ImageListItemWithStyle, RECYCLE_BIN_DURATION } from '../../../../../redux/slices/editor/editorModels'
import { setCurrentFolderEmpty, setSelectedMediaSrcIds } from '../../../../../redux/slices/editor/editorSlice'
import moment from 'moment'
//----------------------------------------

type Props = {
  className: string,
  item: Media,
  inUse?: number,
  deletable?: boolean,
  isSelected?: boolean,
  isDraggable?: boolean,
}


//----------------------------------------
const DraggableImage: React.FC<Props> = ({className, item, inUse=0, isSelected=false, deletable=true, isDraggable=true}) => { 
  const dispatch = useAppDispatch()
  // --------------------------------------------
  const currPersistContent = useAppSelector(state => state.persistor._content.currContent)    
  const { data: allContents, isLoading, isFetching} = useGetAllContentsQuery(5, { refetchOnMountOrArgChange: true })  

  const currentFolder = useAppSelector(state => state.editor.currentFolder)  

  const multiSelMode = useAppSelector(state => state.editor.multiSelectMode)  
  const selMediaSrcIds = useAppSelector(state => state.editor.selectedMediaSrcIds)  

  // console.log(item)
  // console.log(item, isDraggable)

  // ---------------------------
  const [{isDragging}, drag] = useDrag(
    () => ({
      type: "imageVideo",
      item: {
        id: item.source_id,
        // grp: item.source_group_id,
        // name: item.name,
      },
      collect: (monitor) => {
        // console.log("monitor, item:", monitor, item)          
        return {      
                isDragging: !!monitor.isDragging(),
                handlerId: monitor.getHandlerId(),
              }
      }
    }), [item]  // Require to refresh obj 'item' when it changes, DEPENDENCY ARRAY HERE, need for refreshing
  )

  const limitFileNamelen = 12
  const fileName = item.name.split('.').slice(0, -1).join('.')
  const ext = item.name.split('.').pop()

  const [imgError, setImgError] = useState(false)
  const [loadAttempt, setLoadAttemp] = useState(0);
  // ---Delete Image Handler
  // ----------------------------
  const [updateContent] = useUpdateContentMutation()
  const [deleteDraggableImage] = useDeleteMediaMutation()

  // --------------------------------
  const deleteMediaHandler = async (med: Media, inUse=0) => {     
  
    if (inUse > 0 || currentFolder.source_group_id === "bin" || (!isDraggable) ){   
         
      Swal.fire({
        title: '<h3>Are you sure you want to '+(currentFolder.source_group_id === "bin" || (!isDraggable) ? "permanently":"")+' delete this file?<h3><h1 style="color:#331168; margin: 15px 0;">'+ med.name +'</h1>',              
        text: currentFolder.source_group_id === "bin"? "":"This file is currently in use!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#9C258F',
        cancelButtonColor: '#E4E6EF',
        confirmButtonText: 'Yes, delete it anyway!',
        customClass: {          
            cancelButton: 'order-1 left-gap me-5',
            confirmButton: 'order-2',
            // denyButton: 'order-3',
        }
      })
      .then((result) => {
        if (result.isConfirmed) {

          try{
            if (med.source_id){
              // 1- delete images from all the related contents if any
              // --------------------------------------------------
              // if (inUse > 0){
              //   allContents?.map((content)=> {
              //     const newEleList : EleInput[] = []
              //     var flagUpdate : boolean = false
              //     // ----------------------------
    
              //     content.elements.map((section, indSect) => {
              //       var sourcesAfterDelete : Source[] = []
    
              //       if (["image", "video"].includes(section.element_type.toLowerCase())){
              //         sourcesAfterDelete = section.sources?.filter((src)=>{
              //           return (src.source && src.source.source_id !== med.source_id)                            
              //         })
              //         // console.log("ele.sources: ",section.sources)
              //         // console.log("sourcesAfterDelete: ",sourcesAfterDelete)
    
              //         if (section.sources.length !== sourcesAfterDelete.length){
              //           flagUpdate = true
              //         }
              //       }
    
              //       newEleList.push({
              //         element_id  : section.element_id,
              //         position_id : section.position_id,
              //         alias       : section.alias,                        
              //         element_setting : JSON.stringify(section.element_setting),
              //         type            : section.element_type,
              //         source_ids      : JSON.stringify(flagUpdate === false? section.sources : sourcesAfterDelete),
              //       })
              //     })
    
              //     if (flagUpdate){
              //       const newContent :ContentInput = {
              //         contentId: content.user_template_id?content.user_template_id:"",
              //         user_template: {
              //           template_id : content.template_id?content.template_id:"",
              //           user_template_name: content.user_template_name,
              //           duration: content.duration
              //         },                            
              //         elements: newEleList
              //       }
              //       // console.log("newContent : ", newContent)
                                            
              //       updateContent(newContent) //update the content correspondance
              //     }
              //   })
              // }
    
              // 2- Delete images from currContent
              // --------------------------------------------------
              currPersistContent.elements.map((section, indSect)=>{
                var sourcesAfterDelete : Source[] = []
    
                if (["image", "video"].includes(section.element_type.toLowerCase())){
                  sourcesAfterDelete = section.sources?.filter((src)=>{
                    return (src.source && src.source.source_id !== med.source_id)                            
                  })
                  if (section.sources.length !== sourcesAfterDelete.length){
                    dispatch(setSectionId(section.element_id))  //which section it belongs to
                    dispatch(setContentSource(sourcesAfterDelete))
                  }
                }
              }) 
    
              // 3 - Delete the image source
              // ----------------------------
              deleteDraggableImage(med.source_id)
                                
              const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-right',
                  iconColor: 'green',
                  customClass: {
                  popup: 'colored-toast'
                  },
                  showConfirmButton: false,
                  timer: 2000,
                  // timerProgressBar: true
              })
              Toast.fire({
                  icon: 'success',
                  title: `File: <span class="text-primary fw-bold">'${med.name}'</span><br/> has been succesfully deleted.`,
              })
            }
          }catch(error){
              Swal.fire(
                  'File: '+ med.name,
                  'There is something wrong during the deletion!',
                  'error'
              )                                    
          }
        }        
      })
    }else{
      try{
        if (med.source_id){          

          // 2- Delete images from currContent
          // --------------------------------------------------
          currPersistContent.elements.map((section, indSect)=>{
            // console.log("section", section)

            var sourcesAfterDelete : Source[] = []

            if (["image", "video"].includes(section.element_type.toLowerCase())){
              // console.log(ele.element_type)
              sourcesAfterDelete = section.sources?.filter((src)=>{
                // console.log(src.source?.source_id)
                return (src.source && src.source.source_id !== med.source_id)                            
              })
              // console.log("ele.sources: ",section.sources)
              // console.log("sourcesAfterDelete: ",sourcesAfterDelete)

              if (section.sources.length !== sourcesAfterDelete.length){
                // console.log("delete this")

                dispatch(setSectionId(section.element_id))  //which section it belongs to
                dispatch(setContentSource(sourcesAfterDelete))
              }
            }
          }) 

          // 3 - Delete the image source
          // ----------------------------
          deleteDraggableImage(med.source_id)

          
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-right',
            iconColor: 'green',
            customClass: {
              popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 2000,
            // timerProgressBar: true
          })
          await Toast.fire({
            icon: 'success',
            title: `File: <span class="text-primary fw-bold">'${med.name}'</span><br/> has been succesfully deleted.`,
          })
                    
        }
      }catch(error){
          Swal.fire(
              'File: '+ med.name,
              'There is something wrong during the deletion!',
              'error'
          )                                    
      }
    }    
  }

  //-----------------------------------------
  return (
      <ImageListItemWithStyle
        ref = {currentFolder.source_group_id !== 'bin' && isDraggable ? drag : null}
        className={`w-100 ${isDraggable || multiSelMode? "" : "pe-none"} `}
        style={{overflowX: "hidden"}}
        onClick={()=>{
          if (multiSelMode){
            if (selMediaSrcIds.includes(item.source_id)){
              dispatch(setSelectedMediaSrcIds(selMediaSrcIds.filter(id => id !== item.source_id)))
            }else{
              dispatch(setSelectedMediaSrcIds([...selMediaSrcIds, item.source_id]))
            }
          }
        }}
      >
      {/* <ImageListItem >         */}
        {
          item.type.toLocaleLowerCase() == "pdf" 
          ?
            <div className='rounded-0 w-100 h-80px bg-white bordered-1 border-light-primary-darker'>
              <div className="h-100 d-flex justify-content-center top-50 align-items-center text-center">
                <KTSVG path='/media/svg/files/pdf.svg' className='svg-icon-3x m-0'/>
              </div>
            </div>
          : imgError 
            ? <div className='rounded-0 w-100 h-80px bg-success bordered-1 border-light-primary-darker'>
                <span className="text-danger fw-bold text-center">Error</span><br />
                <span>Please delete and re-upload this image</span>
              </div>
              
            : <img 
                // ref = {drag}
                // alt={item.name}
                loading="lazy"

                className={`${className} rounded-0 bordered-0 
                  ${item.width >= item.height? " w-100 h-auto max-w-landscape" : " w-auto h-auto max-w-portrait"}
                  ${isDragging? 'opacity-25':''}            
                `}
                style={{
                  maxHeight: `${item.width >= item.height? "100px" : "300px"}`, 
                }}

                src={`${item.is_remote === 0 ? item.url_thumb : item.url}`}

                onError={({ currentTarget }) => {
                  currentTarget.src= '../media/icons/loading.gif';
                  currentTarget.draggable = false
                  
                  setTimeout(function(){
                    currentTarget.src = item.url_thumb
                    currentTarget.draggable = true
                    setLoadAttemp(loadAttempt+1)
                    if(loadAttempt == DEFAULT_LOAD_ATTEMPT){
                      currentTarget.src = item.url
                    }else if(loadAttempt >DEFAULT_LOAD_ATTEMPT){
                      currentTarget.onerror = null; // prevents looping
                      setImgError(true)                    
                    }
                  }, 1000)
                }}
              />
        }
      
        <ImageListItemBar
          position='top' className='rounded-bottom bg-transparent'
          // subtitle={formatBytes(item.size)}
          // actionIcon={                                                  
          //   <span className="badge badge-gray text-gray-300 opacity-75 fs-9 fw-light p-1 me-1">{formatBytes(item.size)}</span>
          // }
          actionIcon={<>
            {inUse > 0 &&
              <span className='opacity-100 m-0 mt-1 ms-1 p-0 px-1 position-absolute top-0 start-0 bagde tooltip-bottom bg-light rounded-circle'
                style={{borderBottomRightRadius:"5px"}}
                data-tooltip-content='This file is in use!'
              >
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-3 shadow-sm'/>
              </span>
            } 
            {isSelected === true &&
              <span className='opacity-100 m-0 mt-1 ms-1 p-1 pb-0 position-absolute top-0 start-0 bagde bg-info rounded-circle'
                style={{borderBottomRightRadius:"5px"}}
              >
                <i className='bi bi-check text-light fw-bold fs-3 shadow-sm'/>
              </span>
            } 
                       
            { deletable === true && (item.is_remote !== 1) &&
              <a href="#" 
                className={`pe-auto bg-secondary bg-hover-primary text-hover-white opacity-75 m-0 py-0 px-2 position-absolute top-0 end-0 tooltip-bottom`}
                data-tooltip-content={`${currentFolder.source_group_id !== "bin" ? 'Move to Trash':''}`}
                style={{borderBottomLeftRadius:"5px"}}
                onClick={()=>{ deleteMediaHandler(item, inUse);}}
              >
                <i className='bi bi-trash text-dark opacity-75'></i>
              </a> 
            }            
          </>}
        /> 
        
        
        <div className='d-flex justify-content-between m-1 mb-0'>
          <span className='d-flex fs-9 fw-semibold text-muted text-capitalize tooltip-bottom'
            data-tooltip-content={item.name}
            data-html="true"
          >
            <i className={`bi ${item.type.toLocaleLowerCase() == "pdf"  ? "bi-file-earmark-pdf " 
                                                                        : (item.is_remote === 0 ? "bi-image":"bi-share-fill")} text-info me-2`}></i>
            {`${fileName.length <= limitFileNamelen ? fileName: fileName.substring(0, limitFileNamelen)+"..."}.${ext}`}
          </span>          
          {item.size>0 &&
            <span className='fs-9 fw-semibold text-muted'>{formatBytes(item.size)}</span>
          }
        </div>
        {item.deleted_at && 
          <div className='d-flex justify-content-end fs-9 text-muted pe-1'>
            {moment.utc(moment(item.deleted_at, "YYYY-MM-DD HH:mm:ss", true).toString()).local().fromNow()}
            {/* {moment.utc(moment(item.deleted_at, true).toString()).local().fromNow()} */}
            {/* {RECYCLE_BIN_DURATION - moment.utc(moment(item.deleted_at, true).toString()).local().diff(moment(), "days")} days left    */}
          </div>
        }
      {/* </ImageListItem> */}
      </ImageListItemWithStyle>
  )
}

export {DraggableImage}