import {FC} from 'react'
import { useAppDispatch } from '../../redux/hook'
import { resetContentLandscape } from '../../redux/slices/content/contentSlice';
import { Props } from '../../redux/slices/content/contentModels';
import { Link } from 'react-router-dom';
import { setDevice } from '../../redux/slices/device/deviceSlice';
import { emptyDevice } from '../../redux/slices/device/deviceModels';


const ContentHeader: FC<Props> = ({className}) => {

    const dispatch = useAppDispatch()   
    // --------------------------------------------------

    // --------------------------------------------------
    return (
      <div className={`${className} d-flex`}>
            <div className="w-100"> 
                <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">
                  <a href="#">
                    <button 
                        className={`btn text-hover-primary`}                        
                        onClick={() => { 
                          dispatch(resetContentLandscape());  
                          setTimeout(() => {
                            window.location.href = "/editor"
                          }, 1000);                   
                        }}
                    >
                        <i className='bi bi-plus-circle text-gray-900 text-hover-primary fs-5 mt-n1 me-1'></i>
                        <span className='fs-6'>Create new campaign</span>
                    </button>
                  </a>
                  <Link to="/license" className='ms-0'>
                      <button 
                          className={`btn text-hover-primary ps-0 tooltip-bottom`}
                          data-tooltip-content="Need more license(s), let's buy it now!"  
                          
                          onClick={()=> dispatch(setDevice(emptyDevice))}                           
                      >
                          <i className='bi bi-cart4 text-gray-900 text-hover-primary fs-4 mt-n1 me-1'></i>
                          <span className='fs-6'>Purchase license</span>
                      </button>
                  </Link>
                </div>            
            </div>
        </div>
  )
}

export {ContentHeader}
