import { FC } from "react"
import { useAppSelector } from "../../../../redux/hook"
import { SuperboltSocial } from "../../../../commons/widgets/SuperboltSocial"

type Props = {
    id: string,
    className: string
}

const ModalShareCampaign: FC<Props> = ({ id, className = '' }) => {
    const previewServer = `${window.location.origin}` == "https://purple.superbolt.com.au" ? '&s=0' : ''
    const sharedServer = process.env.REACT_APP_PREVIEW_SHARE_URL == undefined ? 'https://superbolt.com.au' : process.env.REACT_APP_PREVIEW_SHARE_URL;
    const currContentPersistor = useAppSelector(state => state.persistor._content.currContent)
    const url = `${sharedServer}/share?id=` + currContentPersistor.user_template_id + `${previewServer}`
    return (
        <div className={`modal fade ${className}`} tabIndex={-1} style={{zIndex: 2051}} id={id}>
            <div className={`modal-dialog modal-lg modal-dialog-centered`} >
                <div className={`modal-content mh-600px`}>
                    <div className="modal-header bg-gradient-primary py-5 rounded-top">
                        <h4 className="text-truncate text-capitalize title text-light">
                            <i className="fa fa-share-alt text-light fs-5 me-2"></i>
                            Share Campaign: "{currContentPersistor.user_template_name}"
                        </h4>
                        <button 
                            className='btn btn-link btn-sm btn-transparent tooltip-bottom'
                            data-bs-dismiss="modal"
                            aria-label="Close"                            
                        >
                            <i className={`fa fa-times text-hover-primary fs-2`}></i>
                        </button>
                    </div>
                    <div className={`modal-body`}>
                        <SuperboltSocial url={url} facebook={true} x={true} whatsapp={true} telegram={true} /> 
                    </div>
                    <div className={`modal-footer p-3 px-3`}>
                        <button className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                            <i className="fa fa-times me-1"></i>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ModalShareCampaign }