import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
  ToggleComponent,
} from '../../../../../../../_metronic/assets/ts/components'
import { SyntheticEvent, useEffect, useState } from 'react'
import { 
  GalleryBreadcrumb,
  GalleryFiles,
  GalleryMenu
} from '../../../EditorComponents'
import { useGetImagesQuery } from '../../../../../../redux/slices/application/pexels/apiPexelsSlice'
import { createClient } from 'pexels';
import { PEXELS_API_KEY } from '../../../../../../redux/slices/application/pexels/pexelsModels';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import { TabContext, TabPanel } from '@mui/lab';
import { Badge, Box, Tab, Tabs, tabsClasses } from '@mui/material';

import Typewriter from 'typewriter-effect';


const TabAI = () => {

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //---------------------------------------------------- 
  const [tabValue, setTabValue] = useState("tab_panel_ai"); 
  const handleChange = (event: SyntheticEvent<Element, Event>, newTabValue: any) => {
      setTabValue(newTabValue); 
  };  

  
  // -------------------------------
  return (
    <div className="d-flex flex-column flex-row-auto w-100 vh-100 bg-gray-300">
        <div className="d-flex flex-column-auto h-70px bg-gradient-primary" 
          style={{
            paddingTop:"2px", 
            // backgroundImage: "linear-gradient(#F04E5D, #9C258F)"
          }}
        >
          {/* Tab Menu */}
          <div className='w-100 mt-8 ms-0 me-0 pt-0 '>         
          
          </div>
        </div>
        
        <div className='container mt-8 ms-0 me-0 pt-0 '>
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString('<h1 class="text-primary mb-2">"Get ready for a revolution!"</h1>')
                .pauseFor(2500)
                .typeString('<h1 class="text-primary mb-6">"AI is coming soon......"</h1>')
                .pauseFor(2500)
                .typeString('<h3>AI is about to transform Superbolt digital signage, bringing cutting-edge innovation right to your screens!</h3>')                
                .callFunction(() => {
                  console.log('String typed out!');
                })
                .pauseFor(2500)
                .deleteAll()
                .callFunction(() => {
                  console.log('All strings were deleted');
                })
                .start()                
            }}
            options={{
              // strings: ['Hello', 'World'],
              autoStart: true,
              deleteSpeed: 50,
              loop: true,
            }}
          />
          
        </div>        
    </div>    
    
  )
}

export {TabAI}
