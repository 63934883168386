import {FC, useEffect, useState} from 'react'
import { useAppDispatch } from '../../redux/hook'
import { StatisticsWidget5, StatisticsWidget7} from '../../../_metronic/partials/widgets'
import { devicesApi } from '../../redux/slices/device/apiDeviceSlice';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { HeaderUserMenu } from '../../../_metronic/partials';
import clsx from 'clsx';
import { setCrudSchedStatus, setCurrScheduleId } from '../../redux/slices/schedule/scheduleSlice';
import { useActivateEmergencyContentMutation, useDeactivateEmergencyContentMutation, useGetAllContentsQuery } from '../../redux/slices/content/apiContentSlice';
import { Content } from '../../redux/slices/content/contentModels';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { setDevice } from '../../redux/slices/device/deviceSlice';
import { emptyDevice } from '../../redux/slices/device/deviceModels';

type Props = {  
  className?:string
}


const ScheduleHeader: FC<Props> = ({className}) => {

    const dispatch = useAppDispatch()    
    // --------------------------------------------------
    const { data: allContents, isLoading, isFetching} = useGetAllContentsQuery(5, { refetchOnMountOrArgChange: true })  
    const [emergContent, setEmergContent] = useState<Content>()
    useEffect(()=>{
        if (allContents){
            setEmergContent(allContents.find((cont)=> cont.emergency === 2))
        }
    },[allContents])

    const [activateEmergencyContent] = useActivateEmergencyContentMutation();
    const activateEmergencyContentHandler = async (cont: Content, typ: string = "content") => {
        Swal.fire({
            title: 'Are you sure you want to activate this Emergency Content?<h1 class="text-capitalize" style="color:#331168; margin: 15px 0;">'+ cont.user_template_name +'</h1>',
            /*text: "You won't be able to revert this!",*/
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#9C258F',
            cancelButtonColor: '#E4E6EF',
            confirmButtonText: 'Yes, activate it!',
            customClass: {          
              cancelButton: 'order-1 left-gap me-5',
              confirmButton: 'order-2',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if(cont.user_template_id) {                   
                    
                    activateEmergencyContent(cont.user_template_id)                

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-right',
                        iconColor: 'green',
                        customClass: {
                          popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    Toast.fire({
                        icon: 'success',
                        title: `Emergency Content <span class='text-primary'>${cont.user_template_name}</span><br/> has been activated.`,
                    })
                }
            }
        })
    }

    const [deactivateEmergencyContent] = useDeactivateEmergencyContentMutation();
    const deactivateEmergencyContentHandler = async (cont: Content, typ: string = "content") => {
        Swal.fire({
            title: 'Are you sure you want to deactivate this Emergency Content?<h1 class="text-capitalize" style="color:#331168; margin: 15px 0;">'+ cont.user_template_name +'</h1>',
            /*text: "You won't be able to revert this!",*/
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#9C258F',
            cancelButtonColor: '#E4E6EF',
            confirmButtonText: 'Yes, stop it!',
            customClass: {          
              cancelButton: 'order-1 left-gap me-5',
              confirmButton: 'order-2',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if(cont.user_template_id) {                   
                    
                    deactivateEmergencyContent(cont.user_template_id)                

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-right',
                        iconColor: 'green',
                        customClass: {
                          popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    Toast.fire({
                        icon: 'success',
                        title: `Emergency Content <span class='text-primary'>${cont.user_template_name}</span><br/> has been deactivated.`,
                    })
                }
            }
        })
    }
    // -----------------------------------
    return (
    <>
      {/* <div className='row g-5 g-xl-8'>
        <div className='col-xl-2'>
            <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='/schedule/icons/duotune/general/gen006.svg'
                color='light-primary'
                iconColor='gray-600'
                titleColor='gray-600'
                descriptionColor='gray-500'
                change="2"
                title='Active'
                description=''          
            />
        </div>
        <div className='col-xl-2'>
            <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='/schedule/icons/duotune/social/soc007.svg'
                color='light-primary'
                iconColor='gray-600'
                titleColor='gray-600'
                descriptionColor='gray-500'
                change="10"
                title='Inactive'
                description=''          
            />
        </div>
      </div> */}
      <div className={`${className} d-flex`}>
        <div className="w-100"> 
            <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">                
                
                <button 
                    className={`btn text-hover-primary  tooltip-bottom`}
                    // data-tooltip-content='Add a new group' 
                    // className="btn btn-primary me-5 tooltip-bottom"
                    // data-bs-toggle="offcanvas"
                    aria-controls="staticBackdrop"
                    data-bs-target="#kt_drawer_crud_schedule"
                    
                    id="kt_drawer_crud_schedule_toggle"
                    data-kt-drawer-show="true" 

                    // data-tooltip-content="Add a new schedule."                    
                    onClick={()=> {
                        dispatch(setCurrScheduleId("")) 
                        dispatch(setCrudSchedStatus({updateSchedMode: false, schedMode: "standard"}))
                    }}
                >
                    <i className='bi bi-calendar-plus text-gray-900 text-hover-primary fs-4 mt-n1 me-1'></i>
                    <span className='fs-6'>Add a schedule</span>
                </button>
                
                {emergContent &&
                    <button 
                        className={`btn text-danger text-hover-primary  tooltip-bottom`}
                        onClick={()=> {
                            deactivateEmergencyContentHandler(emergContent)
                        }}
                    >
                        <i className='fa fa-ban text-danger text-hover-primary fs-4 mt-n1 me-1'></i>
                        <span className='fs-6'>Stop Emergency</span>
                    </button>
                }
    
                <Link to="/license" className='ms-0'>
                    <button 
                        className={`btn text-hover-primary ps-0 tooltip-bottom`}
                        data-tooltip-content="Need more license(s), let's buy it now!"  
                        
                        onClick={()=> dispatch(setDevice(emptyDevice))}                           
                    >
                        <i className='bi bi-cart4 text-gray-900 text-hover-primary fs-4 mt-n1 me-1'></i>
                        <span className='fs-6'>Purchase license</span>
                    </button>
                </Link>


                {/* <DrawerAddGroup />
                <DrawerEditGroup /> */}

                                        {/* <div className={clsx('app-navbar-item ')}>
                                            <div
                                                className={clsx('cursor-pointer symbol d-flex fs-8 text-hover-primary ms-3')}
                                                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                                data-kt-menu-attach='parent'
                                                data-kt-menu-placement='bottom-end'
                                            >
                                                <img src={toAbsoluteUrl('/media/avatars/blank.png')} className='rounded-circle w-25px h-25px' alt='' />
                                                <div className='d-none d-lg-flex flex-column align-item-center mt-1'>
                                                <div className='text-gray-800'>
                                                    <span className='ms-2'>Hi</span> 
                                                </div>
                                                </div>
                                            </div>
                                            <HeaderUserMenu />
                                        </div>  

                                        <div className="btn-group btn-group-sm" role="group" >
                                            <button
                                                className={`btn tooltip-bottom `}
                                                // data-tooltip-content='Create New Folder'
                                                data-kt-menu-trigger="{default: 'click'}"
                                                data-kt-menu-attach='parent'
                                                data-kt-menu-placement='bottom-start'
                                                data-kt-menu-flip='top-end'
                                            >
                                                btn
                                            </button>

                                            <div
                                                className='menu menu-sub menu-sub-dropdown menu-row w-350px w-lg-650px h-250px shadow-lg mt-2'
                                                data-kt-menu='true'
                                                style={{zIndex:"1001"}}
                                            >          
                                                <div className="d-flex flex-column flex-row-auto w-sm-100 w-md-50">          
                                                    <div className="d-flex justify-content-between flex-center bg-light-primary h-50px"
                                                    style={{borderTopLeftRadius:"0.475rem"}}
                                                    // style={{background: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}') no-repeat center center / cover`}}
                                                    >
                                                        <h4 className='text-primary fw-bold px-9 mt-6 mb-6'>
                                                            Create New Folder 
                                                        </h4>                      
                                                    </div>

                                                    <div className="d-flex flex-column-fluid flex-center bg-light" style={{borderBottomLeftRadius:"0.475rem"}}>
                                                        <div className='w-100 my-5 px-8'>
                                                            tttt
                                                        </div>
                                                    </div>
                                                </div>          
                                            </div>
                                        </div>  */}
                {/* <button 
                    className={`btn text-hover-info tooltip-bottom`}
                    data-bs-toggle="offcanvas"
                    aria-controls="staticBackdrop"
                    data-bs-target="#kt_drawer_add_device"
                    
                    id="kt_drawer_add_device_button"
                    data-kt-drawer-show="true" 
                    data-tooltip-content="Add a new device to a group"  
                >
                    <i className='bi bi-display text-primary fs-4 me-1'></i>
                    <span className='fs-6'>Add a device</span>
                </button>
                <DrawerAddDevice/>          
                <DrawerEditDevice/> 

                <a href="/products">
                <button 
                    className={`btn text-hover-info tooltip-bottom`}
                    data-bs-toggle="offcanvas"
                    aria-controls="staticBackdrop"
                    data-bs-target="#kt_drawer_add_device"
                    
                    id="kt_drawer_add_device_button"
                    data-kt-drawer-show="true" 
                    data-tooltip-content="Need more devices, let's purchase more devices from our store."  
                >
                    <i className='bi bi-cart4 text-primary fs-4 me-1'></i>
                    <span className='fs-6'>Purchase device(s)</span>
                </button>
                </a> */}

            </div>            
        </div>
    </div> 
    </>
  )
}

export {ScheduleHeader}
