/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import { useDrop } from 'react-dnd'
import { useGetAllMediasQuery } from '../../../../../redux/slices/media/apiMediaSlice'
import { Media, Medias, defaultThumbnails } from '../../../../../redux/slices/media/mediaModels'
import { DIMENSION_CONVERSION, Element, IMAGEDURATION, ParamSource, Source, getBgTemplateSize, templateColorPallets } from '../../../../../redux/slices/content/contentModels'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hook'
import { getCorrespondHeight, getCorrespondWidth, getSectColorCodes, getSectionIcon } from '../../../../../redux/slices/editor/editorModels'
import { setContentSource, setHoverLayerId, setHoverLayerOrder, setHoverSectionId, setSectionId } from '../../../../../redux/slices/content/contentSlice'
import { setCurrentFolder, setCurrentFolderEmpty, setMultiSelectMode } from '../../../../../redux/slices/editor/editorSlice'
import moment from 'moment'


//----------------------------------------

type Props = {
  className: string,  
  section: Element,
  sectionId: string
}

type Item = {
  id: string,
  url: string
}

const ARR_FALSE = [
  false, false, false, false, false, false, false, false, false, false,
  false, false, false, false, false, false, false, false, false, false,
  false, false, false, false, false, false, false, false, false, false,
  false, false, false, false, false, false, false, false, false, false,
  false, false, false, false, false, false, false, false, false, false
]

//section in this component is not automatically updated // pass by value... need to make it auto-update
//----------------------------------------
const DroppableImageVideoZone: React.FC<Props> = ({className, section, sectionId}) => { 
  const dispatch = useAppDispatch()  
  //-----------------------------------------  
  // console.log("section in DroppableImgVideoZone:", section)
  // console.log("sectionId in DroppableImgVideoZone:", sectionId)
  

  const { data: allMedias, isLoading, isFetching} = useGetAllMediasQuery(5, { refetchOnMountOrArgChange: true })  
  // console.log("allMedias in DroppableImgVideoZone",allMedias)

  const currEditorW = useAppSelector(state => state.editor.currEditorWidth)
  const currEditorH = useAppSelector(state => state.editor.currEditorHeight)
  const currEditorZoom = useAppSelector(state => state.editor.zoomPercentage)

  const currContentPersistor = useAppSelector(state => state.persistor._content.currContent)
  // console.log("currContentPersistor in DroppableImageVideoZone: ", currContentPersistor)
  
  const currentFolder = useAppSelector(state => state.editor.currentFolder)  

  // console.log('=====================================')
  const unselectedSectIds = useAppSelector(state => state.content.unselectedSectionIds)  
  // console.log('unselectedSectIds:',unselectedSectIds)
  const currSectId = useAppSelector(state => state.content.currSectionId)  
  // console.log('currSectId:',currSectId)
  const currLayerId = useAppSelector(state => state.content.currLayerId)  
  // console.log('currLayerId:',currLayerId)
  const hoverSectId = useAppSelector(state => state.content.hoverSectionId)  
  // console.log('hoverSectId:',hoverSectId)
  const hoverLayerId = useAppSelector(state => state.content.hoverLayerId)  
  // console.log('hoverLayerId:',hoverLayerId)
  const hoverLayerOrder = useAppSelector(state => state.content.hoverLayerOrder)  
  // console.log('hoverLayerOrder:',hoverLayerOrder)
  const hoverLayerSourceId = useAppSelector(state => state.content.hoverLayerSourceId)  
  // console.log('hoverLayerSourceId:',hoverLayerSourceId)


  const newSelectedContentIds = useAppSelector(state => state.editor.newSelectedContentIds)  
  // console.log('newSelectedContentIds in video zone:',newSelectedContentIds)
  
  const [addFiles, setAddFiles]= useState<boolean>(false)

  // const selectedLayer = currContentPersistor.elements.find((sect)=>sect.element_id === currSectId)?.sources.find((layer)=>layer.source_list_id === currLayerId)
  // console.log('selectedLayer111:',selectedLayer)

  
  const [sect, setSect] = useState<Element>(section)
  const [boardSection, setBoardSection] = useState<Media[]>([])
  // console.log("boardSection: ",boardSection)
  const [imgErrors, setImgErrors] = useState<number[]>([])
  // console.log("imgErrors: ",imgErrors)

  // const [loadAttempt, setLoadAttemp] = useState(0);
  // -------------------------------------------------------  

  
  // ---------------------------
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "imageVideo",
    drop: (item: Item) => {
      // console.log("dropped item:", item)
      addImageVideoToDropZone(item.id, allMedias)
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),  
  }), [currContentPersistor, allMedias])   //the state needed must be here !!!


  // ----------------------------------
  const initSectionItems = () =>{
    var arrItems: any[] = []  //a variable to store an array of items
    // ------------------------
    const recentSect = currContentPersistor.elements.find((ele)=> {return ele.element_id === section.element_id})
    recentSect?.sources.map((source)=>{
      const itemList =  allMedias?.data.filter((file)=> {return (file.source_id == source.source_id)})
      {itemList && arrItems.push({
          ...itemList[0],           
          duration: (itemList.length > 0? itemList[0].duration : IMAGEDURATION)
        })
      }
    })    
    return arrItems
  }


  // ---------------------------
  const addImageVideoToDropZone = (id: string, allMedias: Medias | undefined) => {

    var arrItems: any[] = initSectionItems()   
    const itemList =  allMedias?.data.filter((file)=> {return (file.source_id == id)})

    if (itemList){
      itemList[0] = {
        ...itemList[0],
        duration : (itemList[0].duration && itemList[0].duration>0 ? itemList[0].duration : IMAGEDURATION)
      }
    }
    {itemList && arrItems.push(itemList[0])}

    // console.log("itemList",itemList)
    // console.log("arrItems",arrItems)

    // Add a dropped item to the boardSection
    // -------------------------------------------------    
    {arrItems && setBoardSection(arrItems) }
    
    // Add a dropped item to the currContentSource
    // -------------------------------------------------
    const recentSect = currContentPersistor.elements.find((ele)=> {return ele.element_id === section.element_id})

    // var sourceList: Source[] = recentSect? (recentSect.sources? recentSect.sources:[]):[]
    var sourceList: Source[] = []
    var sourceOrder: number = 1
    recentSect?.sources.map((src)=>{
      sourceList = [...sourceList, {...src, order: src.order}]
      if(sourceOrder <= src.order) {sourceOrder = src.order+1}
    })
    

    let srcDuration = IMAGEDURATION
    if (itemList && (itemList[0].type === "video" || itemList[0].type === "audio")){
      srcDuration = itemList[0].duration
    }


    // Determine the values of paramSrc
    // ------------------------------------------
    // - Remember the last slide's setting
    // - fit center, if (landscape section with portrait image/video) or inverse
    let newParamSrc: ParamSource[] = [{
                                        delay: 0,
                                        duration: srcDuration,
                                        volume: null,

                                        bg_color:  sourceList.length> 0 ? sourceList[0].param_source[0].bg_color : "#00000000"
                                      }]
    if ((recentSect && itemList) && 
        (
          ((recentSect.w >= recentSect.h) && (itemList[0].width <  itemList[0].height)) ||  //for landscape section, portrait  items
          ((recentSect.w <  recentSect.h) && (itemList[0].width >= itemList[0].height))     //for portrait  section, landscape items
        )
    ){  
      newParamSrc = [{
                      ...newParamSrc[0],
                      fill_to:   "fit",
                      alignment: "center"
                    }]
    }else{
      newParamSrc = [{
                      ...newParamSrc[0],
                      fill_to:   sourceList.length> 0 ? sourceList[0].param_source[0].fill_to  : "",
                      alignment: sourceList.length> 0 ? sourceList[0].param_source[0].alignment: ""
                    }]
    }


    // -----------------------------------
    var newSource: Source = {
      source_list_id: Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
      element_id: Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
      source_id: itemList? itemList[0].source_id:"",      
      order:  sourceOrder,      
      duration_override: -1, 
      param_source : newParamSrc,     
      source:  itemList? itemList[0]:null,
    }
    // console.log("newSource: ",newSource)


    sourceList = [newSource, ...sourceList ] //do not use push
    // console.log("sourceList after: ",sourceList)

    dispatch(setSectionId(sect.element_id))  //which section it belongs to
    dispatch(setContentSource(sourceList))


    // dispatch(setLayerId(newSource.source_list_id))
    // dispatch(setLayerOrder(sect.sources.length))

    dispatch(setHoverSectionId(sect.element_id))
    // dispatch(setHoverLayerId(newSource.source_list_id))    
    // dispatch(setHoverLayerOrder(sect.sources.length))                      
    
  }

  // ----------------------------------
  useEffect(() => {    
    var arrItems: any[] = initSectionItems()  //load items from section
    {arrItems && setBoardSection(arrItems)}
  }, [currContentPersistor, sect, allMedias])

  //-----------------------------------------
  // Main program here
  //-----------------------------------------
  return (
      <div 
        ref = {drop}
        key={sect.element_id} 
        onMouseOver={()=> {
          setAddFiles(true)
          dispatch(setHoverSectionId(section.element_id))
        }}
        onMouseOut={()=> {
          setAddFiles(false)
          dispatch(setHoverSectionId(""))
        }}       
        style={{
          background: boardSection.length? ``:`no-repeat ${getBgTemplateSize(sect.w, sect.h)} ${templateColorPallets[0]} url(${defaultThumbnails[0]})`,
          position:'absolute',          
          width: `${getCorrespondWidth (section.w, section.h, currEditorW, currEditorH, currEditorZoom)}px`,
          height:`${getCorrespondHeight(section.w, section.h, currEditorW, currEditorH, currEditorZoom)}px`,          
          left:  `${getCorrespondWidth (section.x, section.y, currEditorW, currEditorH, currEditorZoom)}px`,
          top:   `${getCorrespondHeight(section.x, section.y, currEditorW, currEditorH, currEditorZoom)}px`,
        }}
        className={`${className} border             
          ${boardSection.length? 'border-0 border-gray-300':'border-2 border-gray-500 '}  
          ${isOver? ' bg-primary opacity-50':''}
          ${unselectedSectIds.length>0 && unselectedSectIds.includes(section.element_id) ? "d-none":""}
        `}        
      >
        { boardSection.slice(0).reverse().map((item, index)=> { 
            let arrIndexFound: number[] = []
            const arrSrcs: Source[] = section.sources.filter((src, ind)=> {
              if (src.source_id === item.source_id){
                arrIndexFound.push(ind)
                return true
              }else{
                return false
              }
            })

            // ---------------------------

            const seletedLayerOrder = hoverLayerOrder && hoverLayerOrder>=0 ? (section.sources.length - hoverLayerOrder): 0

            const foundIndex = arrIndexFound.findIndex(ele => ele === seletedLayerOrder)
            
            const currSrc: Source | undefined = arrSrcs[foundIndex]
            const currSrcParams: ParamSource | undefined = (currSrc && currSrc.param_source[0]) ? currSrc.param_source[0]: undefined

            // console.log("---------------------------------")
            // console.log("foundIndex:", foundIndex)
            // console.log("arrIndexFound:", arrIndexFound)
            // console.log("arrSrcs:", arrSrcs)
            // console.log("seletedLayerOrder:", seletedLayerOrder)

            // console.log("currSrc img/video:", currSrc)
            // console.log("currSrcParams:", currSrcParams)

            let screenClassName = "d-none"
            let containerClassName = "w-100 h-100"
            let containerStyle = ["",""]

            if (currSrcParams){
              //--- Fill_to---------------------------
              if (!currSrcParams.fill_to || currSrcParams.fill_to  === "" || currSrcParams.fill_to  === "full"){ //Full stretching
                screenClassName=""
                containerClassName = "w-100 h-100"
                containerStyle = ["", ""]

              }else if (currSrcParams.fill_to === "fit"){ //Fit to the screen
                screenClassName = "d-flex"
                if (section.w >= section.h){
                  containerClassName = "h-100 "
                  containerStyle = ["fit-content", "auto"] //style [width, height]
                }else{
                  containerClassName = "w-100 "
                  containerStyle = ["auto", "fit-content"]
                }

              }else if (currSrcParams.fill_to === "original"){  //original size
                  screenClassName = "d-flex"

                  const maxEditorPixels = (currEditorW >= currEditorH) ? currEditorW: currEditorH
                  // const itemW = getCorrespondWidth (item.width, item.height, currEditorW, currEditorH, currEditorZoom)
                  // const itemH = getCorrespondHeight(item.width, item.height, currEditorW, currEditorH, currEditorZoom)
                  const itemW = getCorrespondWidth (item.width, item.height, 1920, 1080, currEditorZoom)
                  const itemH = getCorrespondHeight(item.width, item.height, 1920, 1080, currEditorZoom)

                  // console.log("item: ", item.width, item.height)
                  // console.log("item After: ", itemW, itemH)
                  // console.log("item After 2: ", itemW / maxEditorPixels, itemH / maxEditorPixels)


                  containerStyle = [Math.floor(itemW)+"px", Math.floor(itemH)+"px"]
              }

              //--- Alignment---------------------------
              if (!currSrcParams.alignment || currSrcParams.alignment  === ""){
                screenClassName += " align-items-start justify-content-start"
              }else if (["center"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-center justify-content-center"
              }else if (["left"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-center justify-content-start"
              }else if (["right"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-center justify-content-end"

              }else if (["top"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-start justify-content-center"
              }else if (["top left"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-start justify-content-start"
              }else if (["top right"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-start justify-content-end"

              }else if (["bottom"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-end justify-content-center"
              }else if (["bottom left"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-end justify-content-start"
              }else if (["bottom right"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-end justify-content-end"
              }
            }
            
            // console.log("screenClassName: ", screenClassName)
            // console.log("containerClassName: ", containerClassName)            
            // console.log("containerStyle: ", containerStyle)

            // ----------------------------------            
            return (
              <Fragment key={index}>                              
                {
                  {
                    'image':  <div 
                                className={`SCR position-absolute w-100 h-100 
                                  ${screenClassName}
                                  ${((sect.element_id === hoverSectId && item.source_id === hoverLayerSourceId) || 
                                    (hoverLayerSourceId === "" ))                                     
                                    ? "":"d-none"
                                  }
                                  ${currSrc ? "":"d-none"}                                  
                                `}
                                style={{
                                  zIndex: `${sect.element_id === hoverSectId && item.source_id === hoverLayerSourceId ? "1":"0"}`,
                                  backgroundColor: `${currSrcParams ? currSrcParams.bg_color : "#00000000"}`
                                }}                                
                              >
                                <div 
                                  className={`CTNer ${containerClassName} `}
                                  style={{
                                    width:  `${containerStyle[0]}`,
                                    height: `${containerStyle[1]}`
                                  }}
                                >
                                  <img 
                                    key = {`${item.source_id}`} 
                                    id={`${item.source_id}`}
                                    className={`border w-100 h-100   
                                      ${ sect.element_id === hoverSectId ? "section-outline-hover-primary":"border-0"}
                                    `}
                                    // style={{position:"absolute", top:"0"}}
                                    // src={`${item.url}?w=248&fit=crop&auto=format`}
                                    // srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    // src   ={`${item.url}?w=248&fit=crop&auto=format&v=${moment().format('HH-mm-ss-a')}`}
                                    // srcSet={`${item.url}?w=248&fit=crop&auto=format&v=${moment().format('HH-mm-ss-a')}&dpr=2 2x`}
                                    src   ={`${item.url}?w=248&fit=crop&auto=format&v=${moment().format('HH-mm-a')}`}
                                    srcSet={`${item.url}?w=248&fit=crop&auto=format&v=${moment().format('HH-mm-a')}&dpr=2 2x`}
                                    alt={item.name}
                                    loading="lazy"
                                    crossOrigin="anonymous"   
                                  />
                                </div>
                              </div>
                    , 
                    'video':  <div 
                                className={`SCR position-absolute w-100 h-100 border 
                                  ${screenClassName}
                                  ${(sect.element_id === hoverSectId && item.source_id === hoverLayerSourceId) || 
                                    (hoverLayerSourceId === "" )  
                                    ? "":"d-none"
                                  }                                  
                                `}                                
                                style={{
                                  zIndex: `${sect.element_id === hoverSectId && item.source_id === hoverLayerSourceId ? "1":"0"}`,
                                  backgroundColor: `${currSrcParams ? currSrcParams.bg_color : "#00000000"}`
                                }}
                              >
                                <div 
                                  className={`CTNer ${containerClassName} `}
                                  style={{
                                    width:  `${containerStyle[0]}`,
                                    height: `${containerStyle[1]}`
                                  }}
                                >
                                  {imgErrors.includes(index) &&
                                    <video autoPlay={false} controls={false} muted 
                                      className={`w-100 h-100
                                        ${sect.element_id === hoverSectId ? "section-outline-hover-primary":"border-0"} 
                                      `}   
                                      // loading="lazy"
                                      // crossOrigin="anonymous"                                
                                    >
                                      <source src={`${item.url}#t=3`} type="video/mp4"/>
                                    </video>
                                  }
                                  {! imgErrors.includes(index) &&
                                    <img 
                                      key = {`${item.source_id}`} 
                                      id={`${item.source_id}`}
                                      className={`border w-100 h-100
                                        ${ sect.element_id === hoverSectId ? "section-outline-hover-primary":"border-0"} 
                                      `}
                                      // style={{position:"absolute", top:"0"}}                                      
                                      src   ={`${item.url_thumb}?w=248&fit=crop&auto=format&v=${moment().format('HH-mm-a')}`}
                                      srcSet={`${item.url_thumb}?w=248&fit=crop&auto=format&v=${moment().format('HH-mm-a')}&dpr=2 2x`}

                                      alt={item.name}
                                      loading="lazy"
                                      crossOrigin="anonymous" 

                                      onError={({ currentTarget }) => {
                                        currentTarget.src= '../media/icons/loading.gif';
                                        currentTarget.draggable = false
                                        var tmpArr = [...imgErrors]
                                        tmpArr.push(index)
                                        setImgErrors(tmpArr)                    
                                        
                                        // setTimeout(function(){
                                        //   currentTarget.src = item.url_thumb
                                        //   currentTarget.draggable = true
                                        //   // setLoadAttemp(loadAttempt+1)
                                        //   // if(loadAttempt == DEFAULT_LOAD_ATTEMPT){
                                        //     // currentTarget.src = item.url
                                        //   // }else if(loadAttempt >DEFAULT_LOAD_ATTEMPT){
                                        //     // currentTarget.onerror = null; // prevents looping
                                        //     setImgError(true)                    
                                        //   // }
                                        // }, 1000)
                                      }}
                                    />
                                  }
                                </div>
                              </div>
                    ,
                    'audio':  <div className={`SCR position-absolute w-100 h-100 d-block border 
                                ${screenClassName}
                                ${(sect.element_id === hoverSectId && item.source_id === hoverLayerSourceId) || 
                                  (hoverLayerSourceId === "" )  
                                  ? "":"d-none"
                                }                                  
                              `}                                
                              style={{
                                zIndex: `${sect.element_id === hoverSectId && item.source_id === hoverLayerSourceId ? "1":"0"}`,
                                backgroundColor: `${currSrcParams ? currSrcParams.bg_color : "#ffffff"}`
                              }}>
                                <div className='h-100 d-flex border justify-content-center top-50 align-items-center text-center'>
                                  <i className='fa fa-music svg-icon-5x m-0 p-5 fs-1'></i><br />
                                  <div className='text-truncate text-white d-none'>{item.name}</div>
                                </div>
                            </div>,
                    'pdf':  <div className={`SCR position-absolute w-100 h-100 d-block border 
                                ${screenClassName}
                                ${(sect.element_id === hoverSectId && item.source_id === hoverLayerSourceId) || 
                                  (hoverLayerSourceId === "" )  
                                  ? "":"d-none"
                                }                                  
                              `}                                
                              style={{
                                zIndex: `${sect.element_id === hoverSectId && item.source_id === hoverLayerSourceId ? "1":"0"}`,
                                backgroundColor: `${currSrcParams ? currSrcParams.bg_color : "#ffffff"}`
                              }}>
                                <div className='h-100 d-flex justify-content-center top-50 align-items-center text-center'>
                                  <KTSVG path='/media/svg/files/pdf.svg' className='svg-icon-5x m-0 p-5'/><br />
                                  <div className='text-truncate text-white d-none'>{item.name}</div>
                                </div>
                            </div>
                  }[item.type]
                }
              </Fragment>
            )             
          })
        }

        {/* hover part */}
        <div 
          className={`border w-100 h-100 position-absolute top-0 start-0 
            section-outline-hover-primary
            cursor-pointer
          `}
          title={section.sources.length <= 0 ? "Add image/video":"Add more files"}

          style={{background: `#ffffff66 `}}
          hidden={!addFiles}

          data-kt-menu-trigger="{default: 'click'}"                    
          data-bs-toggle='modal'
          data-bs-target="#kt_modal_select_image_video"   

          onClick={() => { 
            dispatch(setSectionId(sect.element_id))

            if (currentFolder.source_group_id === 'bin'){ //for recycle bin, return to root
              dispatch(setCurrentFolderEmpty())
            }else{
              dispatch(setMultiSelectMode(true))
            }
          }}
        >
          <form id="frm-add-content-imgvdeo" className='form h-100' 
            // onSubmit={handleSubmit(formSubmitHandler)}
          >
            <div className='h-100 d-flex justify-content-center top-50 align-items-center text-center'>
              <div className='w-75'>
                <button type="button" className='btn btn-sm btn-primary  opacity-75 p-4 rounded tooltip-bottom'
                  style={{marginRight:"-14px", borderTopLeftRadius:"0", borderBottomLeftRadius:"0"}}
                  data-tooltip-content={section.sources.length <= 0 ? "Add image/video":"Add more files"}                            
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr013.svg' className='svg-icon-2x m-0'/>
                </button>                                  
              </div>
            </div>
          </form>
        </div>

        {/* Label of each section */}
        <div 
          className='position-absolute top-0 d-flex justify-content-between cursor-pointer'
          style={{zIndex: `${section.element_id === hoverSectId ?"104":""}` }} 
          
          data-kt-menu-trigger="{default: 'click'}"                    
          data-bs-toggle='modal'
          data-bs-target="#kt_modal_select_image_video"   

          onClick={() => { 
            dispatch(setSectionId(sect.element_id))

            if (currentFolder.source_group_id === 'bin'){ //for recycle bin, return to root
              dispatch(setCurrentFolderEmpty())
            }else{
              dispatch(setMultiSelectMode(true))
            }
          }}
        >
          <div 
            className={`py-1 px-2 fw-light fs-9 text-white opacity-75
              ${section.element_id === hoverSectId ? "bg-primary": `bg-${getSectColorCodes(section.element_type)}`} 
            `} 
            style={{borderBottomRightRadius:"5px"}}
          >
            <span className='w-20px'>
              {getSectionIcon(section.element_type, "text-white")}                      
            </span>                    
            <span className='fs-8 pt-2 text-truncate w-150px'>
              {sectionId} [{Math.floor(section.w*DIMENSION_CONVERSION)}x{Math.floor(section.h*DIMENSION_CONVERSION)}]                      
            </span>
            {/* <span className='bg-light text-gray-600 px-2 py-1 rounded ms-2'>
              {boardSection.length}
            </span> */}            
          </div>
        </div>

      </div>    
  )
}

export {DroppableImageVideoZone}