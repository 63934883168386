import {FC, useState} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import {   
  ModalPreviewContent,
  TableContents,
  TableContentsAll,
} from './ContentComponents'
import { useAppSelector, useAppDispatch } from "../../../redux/hook"
import { resetContentLandscape, resetContentPortrait } from '../../../redux/slices/content/contentSlice';
import { useGetAllContentsQuery } from '../../../redux/slices/content/apiContentSlice'
import { ContentHeader } from '../ContentHeader'
import { DrawerAddInstantSchedule } from '../../schedule/components/ScheduleComponents'
import { useInView } from "react-intersection-observer";
import { Content } from 'html2canvas/dist/types/css/property-descriptors/content'
import { DrawerAddPlaylist } from '../../playlist/components/PlaylistComponents'
import { ModalShareCampaign } from './modals/ModalShareCampaign'

const Index: FC = () => {
  const dispatch = useAppDispatch()
  //-------------------------------------------
  // const allContents: Content[] = []
  const { data: allContents, isLoading, isFetching} = useGetAllContentsQuery(5, { refetchOnMountOrArgChange: true })  
  // console.log("allContents in index: ", allContents)

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  //----------------------------
  return (
    <>
      <PageTitle breadcrumbs={[]}>Your Contents</PageTitle>

      <div className='d-flex flex-row h-100 bg-gray-200 mb-9 p-0 rounded-bottom' 
        // style={{marginLeft:"-30px", marginRight:"-30px" }}
      >
        <div className='d-flex flex-column flex-row-fluid'>
          <div className="d-flex flex-column-auto flex-start">            
            <ContentHeader className='w-100 h-100 py-1'/>            
          </div>
        </div>
      </div>      

      {!allContents || allContents.length <= 0 &&
        <div className='row gy-5 gx-xl-8 h-md-300 mb-7'>    
          <div className='col-lg-12 col-xl-12 d-flex flex-center'>          
            <img src={toAbsoluteUrl('/media/_content/banner-empty-content-01.png')} className='w-50 mt-10 opacity-25' />
          </div>
          <div className='col-lg-12 col-xl-12 text-center '>
            <h1 className='text-muted'>Content list is Empty.</h1>
            <div className='fs-3 mt-5'>
              <i className='fa fa-hand-point-right text-gray-900 text-hover-primary fs-4 mt-n1 me-2'></i>
              Click 
              <a href="/editor" className='btn btn-sm bg-secondary bg-hover-light-primary px-2 py-1 mx-2'>
              <button 
                  className={`btn text-hover-primary bg-hover-light-primary tooltip-bottom`}
                  // data-tooltip-content='Add a new group' 
                  // className="btn btn-primary me-5 tooltip-bottom"
                  data-bs-toggle="offcanvas"
                  aria-controls="staticBackdrop"
                  data-bs-target="#kt_drawer_add_group"
                  
                  id="kt_drawer_add_group_button"
                  data-kt-drawer-show="true" 
                  
                  data-tooltip-content="Let's create a better content for a greater benefit."
                  onClick={() => { dispatch(resetContentLandscape()); }}
              >
                  <i className='bi bi-plus-circle text-hover-primary fs-4 mt-n1 me-1'></i>
                  <span className='fs-3'>Create new content</span>
              </button>
            </a>
              to design your first content!
            </div>
          </div>        
        </div>    
      }
      {allContents && allContents.length > 0 &&
        <div className='row gy-5 gx-xl-8 h-md-300 mb-7' ref={ref}>        
          <div className='col-lg-12 col-xl-12'>          
            <TableContentsAll 
              className='card-xxl-stretch mb-5 mb-xl-8'            
              inView={inView}
            />
          </div>        
        </div>
      }

      <ModalPreviewContent className="" id="modal-preview-content"/>
      <ModalShareCampaign className='' id='modal-share-campaign' />
      <DrawerAddInstantSchedule />      
      <DrawerAddPlaylist inView={inView}/>      
    </>
  )
}

export {Index}