/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { useGetAllRootFoldersQuery, useUploadFilesMutation } from "../../../../../../../redux/slices/media/apiMediaSlice";
import { useAppSelector } from '../../../../../../../redux/hook'
import FileUpload from '../../../../../../../commons/uploadFiles/FileUpload'
import Swal from 'sweetalert2'
import { useGetAccountStoragAvailableQuery } from '../../../../../../../redux/slices/license/apiLicenseSlice'
import { convertBytesToKB, Props } from '../../../../../../../redux/slices/content/contentModels'
// ---------------------------------------



type UploadedFolderProps = {  
  folderId: string,
  uploadResponses: any
}

const schema = yup.object().shape({
    // folderId: yup.string().required(),
    noFile: yup.number()
         .min(1)
         .required('At least one file selected.')
})


const styleDiv = {
  margin: "1%"
}

const style = {
  width: 200,
  height: 150,
  border: "1px dotted #888"
};

//----------------------------------------
export const ModalUploadFiles: React.FC<Props> = ({className="modal fade "}) => {
  
  const [uploadMedia] = useUploadFilesMutation();
  // -----------------------------------------

  const { data: allFolders } = useGetAllRootFoldersQuery(5, { refetchOnMountOrArgChange: true })  
  const {data: accountStorageAvailable} = useGetAccountStoragAvailableQuery(5, { refetchOnMountOrArgChange: true });    
  
  const currentFolder = useAppSelector(state => state.editor.currentFolder)  
  
  const [fileUploaded, setFileUploaded] = useState(""); //text message of how many files uploaded
  const [complete, setComplete] = useState(false)  
  const [uploadError, setUploadError] = useState(false)
  const [noFile, setNoFile] = useState('');    

  const [totalFileSizeToUpload, setTotalFileSizeToUpload] = useState("")
  const [selectedFolder, setSelectedFolder] = useState({                                                  
                                                  folderId: currentFolder.source_group_id,
                                                  sources: [],
                                              })
  // console.log("selectedFolder: ", selectedFolder)

  const [uploadedFolder, setUploadedFolder] = useState<UploadedFolderProps>({                                                  
                                                        folderId: currentFolder.source_group_id,
                                                        uploadResponses: [],
                                                    })
  // console.log("uploadedFolder in Modal: ", uploadedFolder)

  const [currUploadingFilename, setCurrUploadingFilename] = useState("")
  // console.log("currUploadingFilename in Modal: ", currUploadingFilename)


  const { 
    register, 
    handleSubmit,
    
    formState:{
        errors,
        isDirty,
        
        isLoading,         
        isValid,   
        isSubmitting,
        isSubmitSuccessful,
        
    }
  } = useForm<any>({
      resolver: yupResolver(schema),
      defaultValues:{            
          noFile: 0
      },
      values:{
          noFile: selectedFolder.sources.length
      }
  }) 

  
  // const dropzone = new Dropzone("div.my-dropzone", { url: "/file/post" });
  // const [files, setFiles] = useState([]);
  // const [files, setFiles] = useState<(File & {preview:string})[]>([]);
  // console.log("dropzone files: ", files)

  // const {getRootProps, getInputProps} = useDropzone({
  //   accept: {
  //     'image/*': []
  //   },
  //   onDrop: acceptedFiles => {
  //     setFiles(acceptedFiles.map(file => Object.assign(file, {
  //       preview: URL.createObjectURL(file)
  //     })));
  //   }
  // });

  // const thumbs = files.map(file => (
  //   <div key={file.name}>
  //     <div>
  //       <img
  //         src={file.preview}
  //         // Revoke data uri after image is loaded
  //         onLoad={() => { URL.revokeObjectURL(file.preview) }}
  //       />
  //     </div>
  //   </div>
  // ));

  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
  //   return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  // }, []);


  // ---------------------------------------------
  const updateSources = (files: any) => {
    setComplete(false)
    setSelectedFolder({ ...selectedFolder, sources: files})
  }
  
  const fileSizeToUpload = (totalSize: string) => {
    let availableSize: number = accountStorageAvailable ? accountStorageAvailable : 0;
    setTotalFileSizeToUpload(totalSize)
    if(parseInt(totalSize) > availableSize){
      setUploadError(true)
    }
  }

  // ----convert to base64
  const imgToBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      if (file.type.match("image")) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      } else {
        reject("file not valid");
      }
  });


  // ----convert video to thumbnails
  // -------------------------------
  const importFileandPreview = (file: File, revoke?:any) => {
    return new Promise((resolve, reject) => {
        window.URL = window.URL || window.webkitURL;
        let preview = window.URL.createObjectURL(file);
        // remove reference
        if (revoke) {
            window.URL.revokeObjectURL(preview);
        }
        setTimeout(() => {
            resolve(preview);
        }, 100);
    });
  }

  // const generateVideoThumbnails = async (videoFile: File, numberOfThumbnails: number) => {
  //   let thumbnail : any[] = [];
  //   let fractions : any[] = [3];
  //   // -----------------------------
  //   return new Promise(async (resolve, reject) => {
  //       if (!videoFile.type?.includes("video")) reject("not a valid video file");
  //       // await getVideoDuration(videoFile).then(async (duration: any) => {
  //       //     // divide the video timing into particular timestamps in respective to number of thumbnails
  //       //     // ex if time is 10 and numOfthumbnails is 4 then result will be -> 0, 2.5, 5, 7.5 ,10
  //       //     // we will use this timestamp to take snapshots
  //       //     for (let i = 0; i <= duration; i += duration / numberOfThumbnails) {
  //       //         fractions.push(Math.floor(i));
  //       //     }
  //       //     console.log("fractions: ", fractions)
  //           // the array of promises
  //           let promiseArray = fractions.map((time) => {
  //               return getVideoThumbnail(videoFile, time)
  //           })
  //       //     // console.log('promiseArray', promiseArray)
  //       //     // console.log('duration', duration)
  //       //     // console.log('fractions', fractions)
  //           await Promise.all(promiseArray).then((res) => {
  //               res.forEach((res) => {
  //                   // console.log('res', res.slice(0,8))
  //                   thumbnail.push(res);
  //               });
  //               // console.log('thumbnail', thumbnail)
  //               resolve(thumbnail);
  //           }).catch((err) => {
  //               console.error(err)
  //           }).finally(() => {
  //               resolve(thumbnail);
  //           })
  //       // });
  //       reject("something went wrong");
  //   });
  // };

  // --------------------------------------
  // const getVideoThumbnail = (file: File, videoTimeInSeconds: number) => {
  //     return new Promise((resolve, reject) => {
  //         if (file.type.match("video")) {
  //             importFileandPreview(file).then((urlOfFIle: any) => {
  //                 var video = document.createElement("video");
  //                 var timeupdate = function () {
  //                     if (snapImage()) {
  //                         video.removeEventListener("timeupdate", timeupdate);
  //                         video.pause();
  //                     }
  //                 };
  //                 video.addEventListener("loadeddata", function () {
  //                     if (snapImage()) {
  //                         video.removeEventListener("timeupdate", timeupdate);
  //                     }
  //                 });
  //                 var snapImage = function () {
  //                     var canvas = document.createElement("canvas");
  //                     canvas.width = video.videoWidth;
  //                     canvas.height = video.videoHeight;
  //                     // canvas.width = Math.floor(video.videoWidth/2);
  //                     // canvas.height = Math.floor(video.videoHeight/2);
  //                     canvas.getContext("2d")?.drawImage(video, 0, 0, canvas.width, canvas.height);
  //                     var image = canvas.toDataURL();
  //                     var success = image.length > 100000;
  //                     if (success) {
  //                         URL.revokeObjectURL(urlOfFIle);
  //                         resolve(image);
  //                     }
  //                     return success;
  //                 };
  //                 video.addEventListener("timeupdate", timeupdate);
  //                 video.preload = "metadata";
  //                 video.src = urlOfFIle;
  //                 // Load video in Safari / IE11
  //                 video.muted = true;
  //                 video.playsInline = true;
  //                 video.currentTime = videoTimeInSeconds;
  //                 video.play();
  //             });
  //         } else {
  //             reject("file not valid");
  //         }
  //     });
  // };

  /**
   *
   * @param videoFile {File}
   * @returns {number} the duration of video in seconds
   */
  // const getVideoDuration = (videoFile: File)=> {
  //     return new Promise((resolve, reject) => {
  //         if (videoFile) {
  //             if (videoFile.type.match("video")) {
  //                 importFileandPreview(videoFile).then((url) => {
  //                     let video = document.createElement("video");
  //                     video.addEventListener("loadeddata", function () {
  //                         resolve(video.duration);
  //                     });
  //                     video.preload = "metadata";
  //                     video.src = url? url+"": "";
  //                     // Load video in Safari / IE11
  //                     video.muted = true;
  //                     video.playsInline = true;
  //                     video.play();
  //                     //  window.URL.revokeObjectURL(url);
  //                 });
  //             }
  //         } else {
  //             reject(0);
  //         }
  //     });
  // };

  // -----------------------
  const formSubmitHandler: SubmitHandler<any> = async (data) => {
  
      if(selectedFolder.sources.length < 1){
          setNoFile("At least one file is selected")
      }else{
        var c = 0
        var cUploaded = 0;
        var tmpUploadedFlder : UploadedFolderProps = uploadedFolder
        const arrSources : File[] = selectedFolder.sources
        //----------------------------------
        setUploadError(false)

        selectedFolder.sources.forEach(async (source: File, index, array) => {
          getFileAttributes(source).then(async (file: any) => {
            const formData = new FormData();
            
            // console.log("source in fupld: ", source)              
            // let thumbnail64 = ""              
            // try{
            //   let resp: any
            //   // ------------
            //   if (source.type.match("image")){
            //     resp = imgToBase64(source)
            //     // console.log("resp img: ", resp)
            //   }else if (source.type.match("video")){
            //     resp = await generateVideoThumbnails(source, 3)
            //     // console.log("resp video: ", resp[0])
            //   }
            //   thumbnail64 = resp
            // }catch(error){
            //   console.log("error", error)
            // }
            // console.log("thumbnail64 in fupld: ", thumbnail64)
            
            
            formData.append("source_group_id", currentFolder.source_group_id)
            formData.append("is_remote", "0")
            formData.append("src_filepath[]", source)
            formData.append("i_url[]", "")
            formData.append("is_remote[]", "0")
            formData.append("url[]", "")
            formData.append("durationList[]", file[0])
            formData.append("widthList[]",    file[1])
            formData.append("heightList[]",   file[2])
            formData.append("thumbnails[]",   file[3])               

            setFileUploaded("Upload in progress")
            setCurrUploadingFilename("")

            try{
              setCurrUploadingFilename(arrSources[c].name)
              setFileUploaded("Uploading file " + `${c+1}` + " of " + `${array.length}`)               

              await uploadMedia(formData).then(async (response: any) =>{
                
                if (response.data){
                  cUploaded++;
                  tmpUploadedFlder =  { ...tmpUploadedFlder,
                                        uploadResponses : [...tmpUploadedFlder.uploadResponses, ...response.data ]  //update
                                      } 
                }

                setCurrUploadingFilename(arrSources[c].name)
                setUploadedFolder(tmpUploadedFlder)

                setFileUploaded("Uploading file " + `${(++c)+1}` + " of " + `${array.length}`)      

                // --- After done uploading
                if(c >= array.length){      
                  setComplete(true)
                  setFileUploaded("")
                  setTotalFileSizeToUpload("")

                  Swal.fire({
                    icon: cUploaded > 0 ? 'success':'warning',
                    title: cUploaded > 0 ? cUploaded +' Files<br/><br/> have been successfully uploaded!<br/>&nbsp;<br/>' : 'No file has been uploaded, please try again!',
                    text: '',
                    showConfirmButton: true,
                    confirmButtonColor: '#9C258F',
                    confirmButtonText: '<button class="btn btn-sm bg-primary text-light px-10 py-3 mt-n9 mb-n8 mx-n5" data-bs-dismiss="modal" data-bs-target="#kt_modal_upload_files">OK</button>',
                  }).then(function() {
                    
                    setComplete(false)
                    setCurrUploadingFilename("")
                    setSelectedFolder({ ...selectedFolder, sources: []})   
                    setUploadedFolder({ ...uploadedFolder, uploadResponses: []})   

                    // console.log(document.getElementsByClassName("modal-backdrop"))
                    // setShow("modal fade")
                    // document.getElementsByClassName("modal-backdrop")[0].remove();
                    // $("#kt_modal_upload_files").modal("hide");
                    
                    // console.log("document.getElementById('kt_modal_upload_files'): ", document.getElementById('kt_modal_upload_files'))

                  })
                }
              })

              

            }catch(error){
              console.log("error:", error)
            }
            
          })
        })

      }  
   
  }

  // ---------------------------------------------
  const getFileAttributes = (file: File) =>  {

    return new Promise<any>((resolve, reject) => {
      if (file) {
        if (file.type.match("video")){
          let url = (URL || webkitURL).createObjectURL(file)
          let video = document.createElement("video");

          video.addEventListener("loadeddata", function () {
            var canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext("2d")?.drawImage(video, 0, 0, canvas.width, canvas.height);
            var dataThumb = canvas.toDataURL();
            resolve([video.duration, video.videoWidth, video.videoHeight, dataThumb]);
           });
          video.preload = "metadata";
          video.src = url;
          video.muted = true;
                  video.playsInline = true;
                  video.currentTime = 1;
                  video.play();
        }else if(file.type.match("audio")){
          let url = (URL || webkitURL).createObjectURL(file)
          let audio = document.createElement("audio");
          audio.addEventListener("canplaythrough", function(e){
            resolve([audio.duration, 0, 0, ""]);
          })
          audio.src = url;
        }else{
          resolve([-1, "", "", ""])
        }
      }
      else{
        reject(0);
      }
    });
  };  

  // ---------------------------------------------
  useEffect(() => { 
    setComplete(false)
    setCurrUploadingFilename("")
    setSelectedFolder({ ...selectedFolder, folderId: currentFolder.source_group_id})
    setUploadedFolder({ ...uploadedFolder, folderId: currentFolder.source_group_id})
  },[currentFolder]) 

  //-----------------------------------------
  return (
    <div 
      className={`${className} modal fade`}
      tabIndex={-1} 
      data-bs-backdrop="static" 
      data-bs-keyboard="false" 
      id='kt_modal_upload_files'
    >
      <div className="modal-dialog modal-lg modal-dialog-centered" >
        <div className="modal-content">          
          <form id="frm-add-device" className='form w-100'
            onSubmit={handleSubmit(formSubmitHandler)}
          >
            <div className="modal-header py-3 px-5 d-flex justify-content-between flex-center bg-gradient-primary">
              <h4 className="modal-title text-white fw-bold px-9">
                Upload Media Files 
                <i className='fa fa-long-arrow-right fs-3 text-gray-400 mx-3'></i>
                <i className='fa fa-folder fs-3 text-gray-400 me-2'></i>
                <span className='text-gray-400 fs-6'>{currentFolder.group_name}</span>
              </h4>
              <button type='button'
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                disabled={(isSubmitting || (fileUploaded !== "") || uploadError) && (!complete)}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </button>
            </div>               
            <div className="modal-body py-3 px-5">
              <div className='w-100 scroll-y mh-550px my-5 px-8'>
                <div className="offcanvas-body text-start">                    
                    <div className="mb-4">
                        <input type="hidden"  {...register("noFile")}  />

                        <FileUpload 
                            accept='.jpg, .png, .jpeg, video/*, .mp3, application/pdf'
                            label=""
                            multiple
                            updateFilesCb={updateSources}
                            totalSize={fileSizeToUpload}
                            selectedFolder = {selectedFolder}
                            uploadedFolder = {uploadedFolder}
                            currentUploadingFile = {currUploadingFilename}
                            isSubmitting = {isSubmitting || fileUploaded !==""}
                        />

                        {/* <div className="my-dropzone"></div> */}
                        {/* <section className="container">
                          <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                          </div>
                          <aside>
                            {thumbs}
                          </aside>
                        </section> */}

                    </div>
                </div>
              </div>              
            </div>
            <div className="modal-footer py-3 px-5">
              {fileUploaded!=="" 
                ? <div className='me-2'>{fileUploaded}</div>
                : <div className='text-start'>
                    <span className={`me-2 ${uploadError ? 'text-danger' : ''}`}>Total upload size: {totalFileSizeToUpload === "" ? 0 : convertBytesToKB(totalFileSizeToUpload)}</span><br />
                    <span className='me-2'>Available storage: {convertBytesToKB(accountStorageAvailable? accountStorageAvailable.toString():"")}</span>
                  </div>
              }
              <button type="button" className="btn btn-secondary"
                // disabled={(isSubmitting || (fileUploaded !== "") || uploadError) && (!complete)}
                data-bs-dismiss="modal"
                aria-label="Close"                                   
              >                  
                <span><i className="fa fa-times me-2 mt-n1"></i>Hide</span>                    
                {/* <span><i className="fa fa-times me-2 mt-n1"></i>Cancel</span>                     */}
              </button>
              
              <button type="submit" className="btn btn-primary"                    
                  disabled={(isSubmitting || (!isValid) || (fileUploaded !== "") || uploadError)}
                  // disabled={(isSubmitting || (!isValid) || (fileUploaded !== "") || uploadError) && (!complete)}
                >        
                  {isSubmitting || fileUploaded !== ""
                    ? <div className='d-flex justify-content-between'>
                        <div className="spinner-border spinner-border-sm text-light mt-1 me-3" role="status">
                          <span className="visually-hidden fs-9">Uploading</span>
                        </div>
                        <div className='fs-6 mt-0'>Uploading</div>
                      </div>
                    : <span><i className="fa fa-upload me-1 mt-n1"></i>Upload</span>                        
                  }
              </button>
              
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
