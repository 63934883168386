/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {getMaster, useAuth} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'
import { Autocomplete, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material'
import { useAppDispatch } from '../../../../app/redux/hook'
import { setChangePassword, setChangeStaffMFA } from '../../../../app/redux/slices/staff/staffSlice'
import { useNavigate } from 'react-router-dom'


const HeaderUserMenu: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  // -------------------------------------------

  const {currentUser, auth, setCurrentUser, saveAuth, logout} = useAuth()
  const master = getMaster()   

  // ----------------------------------------
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} className='rounded-circle' />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.name}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
            { currentUser?.acc_code && 
                <div className='text-muted d-flex align-items-center fs-7 mt-2'>
                  <span className=''>Referral code: </span>
                  <span className='fw-bolder fs-6 text-primary ms-2'> {currentUser.acc_code}</span>              
                </div>
            }
          </div>

        </div>
      </div>

      {/* <div className='separator my-2'></div> */}

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <div className="px-5 d-flex justify-content-between">
          <div className='menu-text text-gray-600 justify-content-start'>
            Advance Mode
            <span className='badge badge-light-success fs-8 px-2 py-1 ms-2'>Pro</span>           
          </div>
          <span className='d-none menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
          <div className="d-flex justify-content-end mt-n2">
            <div className="form-check form-switch form-check-custom form-switch-sm form-check-solid">
              <input className="form-check-input" type="checkbox" value="" id="flexSwitchDefault"/>               
            </div>
            
          </div>
        </div>
      </div> */}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div> */}

      {/* <div className="mb-10">
        <div className="form-check form-switch form-check-custom form-check-solid">
            <input className="form-check-input" type="checkbox" value="" id="flexSwitchDefault"/>
            <label className="form-check-label" for="flexSwitchDefault">
                Default switch
            </label>
        </div>
      </div> */}

      {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div> }

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}
      
    {master && <>
        <div className='separator my-2'></div>
        <div className='menu-item px-5 my-1'>      
          {/* <Autocomplete
            id="combo-box-demo"
            onChange={(event: any, accountName: string | null) => {
              master.map((account, i)=>{
                if(account.name === accountName){
                  saveAuth(account.auth);
                  setCurrentUser(account.user)
                  window.location.reload();
                  
                }
              })
            }}
            options={master.map((item)=>item.name)}        
            sx={{ width: 240 }}        
            renderInput={(params) => <TextField {...params} label="Switch Account" />}
          />*/}
          <Autocomplete
              // {...flatProps}
              options={master.map((item)=>item.name)}
              id="combo-box-demo"
              sx={{ width: 240 }}  
              // freeSolo
              // selectOnFocus
              // includeInputInList
              // sx={{
              //   border: "1px solid #C4C4C4",
              //   borderRadius: "5px",
              //   padding: "5px",
              //   "& .MuiOutlinedInput-root": {
              //       border: "2px solid #000000",
              //       borderRadius: "5",
              //       padding: "0"
              //   },
              //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              //       border: "1px solid #eee"
              //   }
              // }}
              // renderInput={(params) => (
              //   <TextField {...params} 
              //     // label="Search for content" 
              //     placeholder='Search for campaign ...'
              //     variant="standard" 
              //     fullWidth
              //     InputProps={{
              //       ...params.InputProps,
              //       disableUnderline: true,
              //       type: 'search',
              //       startAdornment: (
              //         <InputAdornment position="start">
              //           <i className="fa fa-search ms-3 me-1 text-black" aria-hidden="true"></i>
              //         </InputAdornment>
              //       )
              //     }}
              //     sx={{
              //       border: "0"
              //     }}
              //   />
              // )}
              renderInput={(params) => <TextField {...params} label="Switch Account" />}
              // onChange={(event, value) => setSearchKeyword(value? value:"")}
              onChange={(event, accountName: string | null) => {
                master.map((account, i)=>{
                  if(account.name === accountName){
                    saveAuth(account.auth);
                    setCurrentUser(account.user)
                    // window.location.reload();
                    // navigate("./") 
                    window.location.href= "/"                   
                  }
                })
              }}
            />
        </div>
    </>}
    <div className='separator separator-dashed my-2'></div>
    <div className='menu-item px-5'>
        <a 
          data-bs-toggle="offcanvas"
          aria-controls="staticBackdrop"
          data-bs-target="#kt_drawer_change_password"
          id="kt_drawer_chat_button"
          data-kt-drawer-show="true" 
          onClick={() => dispatch(setChangePassword({'mode':'user'}))} 
          className='menu-link px-5 text-hover-primary'>
        
        <span className='me-2'>Change Password</span>
        </a>
      </div>
      <div className='menu-item px-5'>
        <a 
          data-bs-toggle="offcanvas"
          aria-controls="staticBackdrop"
          data-bs-target="#kt_drawer_setup_mfa"
          id="kt_drawer_setup_mfa_button"
          data-kt-drawer-show="true" 
          onClick={() => {
            let currentMFA = auth?.extra? auth.extra.tfa : 0;
            dispatch(setChangeStaffMFA({mfa:currentMFA, no_cache: Math.random().toString()}))
          }} 
          className='menu-link px-5 text-hover-primary'>
        
        <span className='me-2'>Two Factor Authentication</span>
        </a>
      </div>
    <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>      
    </div>
  )
}

export {HeaderUserMenu}
